
import Header from '@/components/header/Header.component.vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
export default {
    name: 'AccountManagement',
    components: {
        Header,
    },
    setup() {
        const { t } = useI18n();
        const title = t('components.sidebar.menu.accountManagement');
        const router = useRouter();
        const GoBack = () => router.back();
        return { title, GoBack };
    },
};
