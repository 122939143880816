import { api, authApiPrefix } from '@/Api';

/**
 * Fetch user data
 *
 * @param {string} login username or email
 * @param {string} password password
 * @return {object} an user details
 */
export const AuthenticationApi = async (login: string, password: string) => {
    return api.post(`${authApiPrefix}/login`, { login, password });
};
