import { ActivityDataResponse, GetStockDataResponse, StockGlobalDataResponse } from '@/helper/interface/ApiResponse';
import { ActivityFilterForm, GlobalFilterForm } from '@/helper/interface/stock/FilterFormData';
import { StructureGlobalTableData } from '@/services/StructureTableData.service';

export function UseFilterModal() {
    /**
     * Get all domains from global table
     *
     * @param {StockGlobalDataResponse[]} tableData global table data
     * @return {string[]} domains from table data
     */
    const GetDomainsFromGlobalTable = (tableData: StockGlobalDataResponse[]) => {
        let domains: string[] = [];
        tableData.forEach((row: StockGlobalDataResponse) => {
            if (!domains.includes(row.domain)) domains.push(row.domain);
        });
        return domains;
    };
    /**
     * get activities from table data
     *
     * @param {StockGlobalDataResponse[]} tableData global table data
     * @param {string} [domain] a domain
     * @return {string[]} activities from table data
     */
    const GetActivitiesFromGlobalTable = (tableData: StockGlobalDataResponse[], domain?: string) => {
        if (domain) tableData = tableData.filter((row: StockGlobalDataResponse) => row.domain === domain);
        return tableData.map((row: StockGlobalDataResponse) => row.activity);
    };
    /**
     * Get filtered global data
     *
     * @param {GetStockDataResponse} tableData global table data
     * @param {GlobalFilterForm} filterFormData global filter form data
     * @return {GetStockDataResponse} filtered data
     */
    const GetFilteredGlobalData = (tableData: GetStockDataResponse, filterFormData: GlobalFilterForm) => {
        if (filterFormData.Domain.length)
            tableData = StructureGlobalTableData(
                tableData.rows.filter((row: StockGlobalDataResponse) => row.domain === filterFormData.Domain)
            );

        if (filterFormData.Activities.length)
            tableData = StructureGlobalTableData(
                tableData.rows.filter((row: StockGlobalDataResponse) =>
                    filterFormData.Activities.includes(row.activity)
                )
            );

        if (filterFormData.StartDate && filterFormData.EndDate)
            tableData = StructureGlobalTableData(
                tableData.rows.filter((row: StockGlobalDataResponse) =>
                    CheckDateInRange(
                        new Date(row.datalastmodified),
                        new Date(filterFormData.StartDate),
                        new Date(filterFormData.EndDate)
                    )
                )
            );
        if (filterFormData.Arrow.length)
            tableData = StructureGlobalTableData(
                tableData.rows.filter((row: StockGlobalDataResponse) => {
                    let average = Math.floor(row.oneyeardata / 4);
                    let valid: boolean = false;
                    if (!valid && filterFormData.Arrow.includes(1)) {
                        valid = row.threemonthsdata > average;
                    }
                    if (!valid && filterFormData.Arrow.includes(2)) {
                        valid = row.threemonthsdata == average;
                    }
                    if (!valid && filterFormData.Arrow.includes(3)) {
                        valid = row.threemonthsdata < average;
                    }
                    return valid;
                })
            );
        return tableData;
    };
    /**
     * Get filtered activity data
     *
     * @param {ActivityDataResponse[]} tableData activity table data
     * @param {ActivityFilterForm} filterFormData activity filter form data
     * @return {ActivityDataResponse[]} filtered data
     */
    const GetFilteredActivityData = (tableData: ActivityDataResponse[], filterFormData: ActivityFilterForm) => {
        if (filterFormData.StartDate && filterFormData.EndDate)
            tableData = tableData.filter((row: ActivityDataResponse) =>
                CheckDateInRange(
                    new Date(row.added),
                    new Date(filterFormData.StartDate),
                    new Date(filterFormData.EndDate)
                )
            );
        if (filterFormData.Words.length)
            tableData = tableData.filter((row: ActivityDataResponse) =>
                filterFormData.Words.some((word: string) => {
                    const regex = new RegExp(word, 'gi');
                    return row.data.some((dt: string) => regex.test(dt));
                })
            );
        if (filterFormData.AddedUsers.length)
            tableData = tableData.filter((row: ActivityDataResponse) =>
                filterFormData.AddedUsers.includes(row.whoadded.user)
            );
        if (filterFormData.ArchivedUsers.length)
            tableData = tableData.filter((row: ActivityDataResponse) =>
                filterFormData.ArchivedUsers.includes(row.whoarchived.user)
            );
        if (filterFormData.Evolution.length)
            tableData = tableData.filter((row: ActivityDataResponse) => {
                const year = parseInt(row.added.substring(0, 4));
                const month = parseInt(row.added.substring(5, 7));
                const currentDate = new Date().toISOString();
                const currentYear = parseInt(currentDate.substring(0, 4));
                const currentMonth = parseInt(currentDate.substring(5, 7));
                const monthDiff =
                    new Date(currentYear, currentMonth).getMonth() -
                    new Date(year, month).getMonth() +
                    12 * (new Date(currentYear, currentMonth).getFullYear() - new Date(year, month).getFullYear());
                let valid: boolean = false;
                if (!valid && filterFormData.Evolution.includes(1)) valid = monthDiff <= 6;
                if (!valid && filterFormData.Evolution.includes(2)) valid = monthDiff > 6 && monthDiff <= 12;
                if (!valid && filterFormData.Evolution.includes(3)) valid = monthDiff >= 12;
                return valid;
            });
        if (filterFormData.Status.length) {
            if (filterFormData.Status.includes(1) && !filterFormData.Status.includes(-1))
                tableData = tableData.filter((row: ActivityDataResponse) => row.enable);
            else if (filterFormData.Status.includes(-1) && !filterFormData.Status.includes(1))
                tableData = tableData.filter((row: ActivityDataResponse) => !row.enable);
            else tableData = tableData.filter((row: ActivityDataResponse) => !row.enable || row.enable);
        }
        return tableData;
    };
    /**
     * Check a date lie in between two dates or not
     *
     * @param {Date} dateToCheck date to check
     * @param {Date} stDate start date
     * @param {Date} endDate end date
     * @return {Boolean} true or false
     */
    const CheckDateInRange = (dateToCheck: Date, stDate: Date, endDate: Date) =>
        dateToCheck >= stDate && dateToCheck <= endDate;
    /**
     * Open or close filters
     *
     * @param {boolean} isOpen is modal open or not?
     * @return {boolean} true or false
     */
    const ToggleFilter = (isOpen: boolean) => {
        isOpen = isOpen ? false : true;
        return isOpen;
    };
    return {
        GetDomainsFromGlobalTable,
        GetFilteredGlobalData,
        GetActivitiesFromGlobalTable,
        CheckDateInRange,
        GetFilteredActivityData,
        ToggleFilter,
    };
}
