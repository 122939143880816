import { api, validationApiPrefix } from '@/Api';
/**
 * Validate excel linked files
 *
 * @param {FormData} formData
 * Contains: -- TempId, Temporary table id of excel data
 *           -- ActivityId, Id of an activity
 *           -- ArchiveFile, a zip file contains all assets of the excel file
 * @return {Object}
 * Contains: -- archive, extracted file names from archive(if validated)
 *           -- errors, if not validated
 */
export const ValidateArchiveData = async (formData: FormData) => {
    const result = await api.post(`${validationApiPrefix}/validatearchivedata`, formData);
    return result.data;
};
