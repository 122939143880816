
import { UseDataModal } from '@/composables/UseDataModal.composable';
export default {
    name: 'ReadDataModal',
    props: {
        Title: String,
        Data: Object,
        onClose: Function,
    },
    setup() {
        const { Table, TableHeader, isSmallTable, File, DownloadFiles } = UseDataModal();
        return { Table, TableHeader, isSmallTable, File, DownloadFiles };
    },
};
