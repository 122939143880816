
import ErrorModal from '@/components/modals/error-modal/ErrorModal.component.vue';
import { GetFileUrl } from '@/services/File.service';
import { useI18n } from 'vue-i18n';
import { ref } from '@vue/reactivity';
import { GetStockDataResponse, StockGlobalDataResponse } from '@/helper/interface/ApiResponse';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { UseErrorModal } from '@/composables/UseErrorModal.composable';
import { onMounted, watch } from '@vue/runtime-core';
import { convertDateToFranceOrder } from '@/helper/utils/dateUtils';
export default {
    name: 'Global',
    components: {
        ErrorModal,
        Loading,
    },
    props: {
        openActivityPage: Function,
        tableData: Object,
    },
    setup(props: any) {
        const { t } = useI18n();
        const { errorMessage, errorModalOpen, OpenErrorModal, CloseErrorModal } = UseErrorModal();
        const isLoading = ref<Boolean>(false);
        const tableRows = ref<StockGlobalDataResponse[]>([]);
        const easyDataCount = ref<number>(0);
        const mediumDataCount = ref<number>(0);
        const hardDataCount = ref<number>(0);
        const globalDataCount = ref<number>(0);
        const totalThreeMonthsDataCount = ref<number>(0);
        const totalOneYearDataCount = ref<number>(0);
        const toggleSort = ref<number>(1);
        // initialize table data
        const InitializeTableData = (data: GetStockDataResponse) => {
            tableRows.value = data.rows;
            easyDataCount.value = data.easyData;
            mediumDataCount.value = data.mediumData;
            hardDataCount.value = data.hardData;
            globalDataCount.value = data.globalData;
            totalThreeMonthsDataCount.value = data.totalThreeMonthsData;
            totalOneYearDataCount.value = data.totalOneYearData;
        };
        // Get arrow icons as per evolution
        const GetArrowIcons = (threeMonthData: number, twelveMonthData: number) => {
            let average = Math.floor(twelveMonthData / 4);
            let imageName =
                threeMonthData < average || (threeMonthData === 0 && twelveMonthData === 0)
                    ? 'fleche-evolution-rouge.png'
                    : threeMonthData == average
                    ? 'fleche-evolution-orange.png'
                    : 'fleche-evolution-verte.png';
            return require(`@/assets/stock/${imageName}`);
        };
        // Download file
        const DownloadFile = (actId: number, fileName: string) => {
            isLoading.value = true;
            GetFileUrl(actId)
                .then((url: string) => {
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = fileName;
                    link.click();
                    URL.revokeObjectURL(link.href);
                })
                .catch((error) => OpenErrorModal([error]))
                .finally(() => (isLoading.value = false));
        };
        // sort data
        const ToggleSort = (columnName: string, order: number) => {
            tableRows.value = tableRows.value.sort((present: any, next: any) =>
                order === 1
                    ? (present[columnName].charCodeAt(0) - next[columnName].charCodeAt(0), (toggleSort.value = -1))
                    : (next[columnName].charCodeAt(0) - present[columnName].charCodeAt(0), (toggleSort.value = 1))
            );
        };
        // convert date to france order
        const convertDate = (dateToConvert: string) => convertDateToFranceOrder(dateToConvert);
        watch(
            () => props.tableData,
            (newData) => InitializeTableData(newData)
        );
        onMounted(() => {
            if (props.tableData) InitializeTableData(props.tableData);
        });
        return {
            rows: tableRows,
            easyDataCount,
            mediumDataCount,
            hardDataCount,
            globalDataCount,
            totalThreeMonthsDataCount,
            totalOneYearDataCount,
            GetArrowIcons,
            CloseErrorModal,
            t,
            errorMessage,
            errorModalOpen,
            isLoading,
            DownloadFile,
            toggleSort,
            ToggleSort,
            convertDate,
        };
    },
};
