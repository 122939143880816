import { AuthenticationApi } from '@/api/AuthenticationApi.service';
import { GetUserResponse, User } from '@/helper/interface/ApiResponse';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
/**
 *  Get user data
 *
 * @param {string} login username or email
 * @param {string} password password
 * @return {GetUserResponse} user details
 */
export const GetUser = (login: string, password: string) => {
    return new Promise<GetUserResponse>((resolve, reject) => {
        AuthenticationApi(login, password)
            .then((response: AxiosResponse) => {
                const result: GetUserResponse = {
                    User: {
                        Id: response.data.id,
                        Name: response.data.name,
                        Mail: response.data.mail,
                        AccessType: response.data.access_type,
                    },
                    AuthToken: response.headers['x-auth-token'],
                };
                resolve(result);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) reject(i18n.global.t('pages.auth.invalidCredentials'));
                    else reject(error.response.data);
                }
                else reject(error);
            });
    });
};
/**
 * Save user data in local storage
 *
 * @param {User} user user data
 * @return {User} user
 */
export const SetLocalUser = (user: User) => {
    localStorage.setItem('currentUser', JSON.stringify(user));
    return user;
};
/**
 * get user data from local storage
 *
 * @return {User} user
 */
export const GetLocalUser = () => {
    const strUser = localStorage.getItem('currentUser');
    let user: User | null = null;
    if (strUser) user = JSON.parse(strUser);
    return user;
};
