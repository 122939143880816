/**
 * Map row data to table data
 *
 * @param {object[]} excelData excel data
 * @param {object} genericSchema generic schema
 * @return {object[]} structured data for tables
 */
export const MapTableData = (excelData: any[], genericSchema: any) => {
    return excelData.map((exData) => {
        let result: any = {};
        genericSchema.columns.forEach((column: any) => {
            if (column.columnName !== 'Niveau de difficulté') {
                if (column.type === 'array') {
                    column.values.forEach((value: any) => {
                        if (value.type === 'string' || value.type === 'boolean') {
                            if (exData.data[column.dataPropertyName])
                                ManageStringAndBoolean(
                                    exData.data[column.dataPropertyName][value.index],
                                    value.columnName,
                                    result
                                );
                        } else if (value.type === 'object') {
                            value.columns.forEach((col: any) => {
                                if (col.type === 'string' || col.type === 'boolean')
                                    if (
                                        exData.data[column.dataPropertyName] &&
                                        exData.data[column.dataPropertyName][value.index]
                                    )
                                        ManageStringAndBoolean(
                                            exData.data[column.dataPropertyName][value.index][col.dataPropertyName],
                                            col.columnName,
                                            result
                                        );
                                    // specific activity management
                                    else if (column.otherDataPropertyNames) {
                                        column.otherDataPropertyNames.forEach((otherDataPropertyName: string) => {
                                            if (
                                                exData.data[otherDataPropertyName] &&
                                                exData.data[otherDataPropertyName][value.index]
                                            )
                                                ManageStringAndBoolean(
                                                    exData.data[otherDataPropertyName][value.index][
                                                        col.dataPropertyName
                                                    ],
                                                    col.columnName,
                                                    result
                                                );
                                        });
                                    } else result[col.columnName] = '/';
                            });
                        }
                    });
                } else if (column.type === 'object') {
                    column.values.forEach((col: any) => {
                        if (col.type === 'string' || col.type === 'boolean')
                            ManageStringAndBoolean(
                                exData.data[column.dataPropertyName][col.index],
                                col.columnName,
                                result
                            );
                    });
                } else if (column.type === 'string' || column.type === 'boolean')
                    ManageStringAndBoolean(exData.data[column.dataPropertyName], column.columnName, result);
            } else if (exData[column.dataPropertyName]) result[column.columnName] = exData[column.dataPropertyName];
        });
        return result;
    });
};
/**
 * Manage string and boolean type data for mapping
 *
 * @param {string} str the string or boolean(as string) data
 * @param {string} columnName the column name of excel file
 * @param {*} resultObj the return object
 */
const ManageStringAndBoolean = (str: string, columnName: string, resultObj: any) =>
    (resultObj[columnName] = str !== undefined ? str : '/');
/**
 * Map row data to duplicate table data
 *
 * @param {object[]} duplicateData excel and stored data
 * @param {object} genericSchema generic schema
 * @return {object[]} structured data for duplicate tables
 */
export const MapDuplicateTableData = (duplicateData: any[], genericSchema: any[]) => {
    return duplicateData.map((dupData) => {
        let newData: any = MapTableData([dupData.newData], genericSchema)[0];
        let oldData = MapTableData(dupData.duplicates, genericSchema);
        return { index: dupData.newData.index, data: newData, duplicates: oldData };
    });
};
