export default {
  "generic": {
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verified"])},
    "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
    "wrongFileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong file type"])},
    "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keep"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Done"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
    "easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy"])},
    "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medium"])},
    "difficult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Difficult"])},
    "global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify"])},
    "pleaseChooseAData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose a data"])},
    "evolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evolution"])},
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Name"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])},
    "row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Of"])},
    "assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assets"])}
  },
  "components": {
    "sidebar": {
      "menu": {
        "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
        "newContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Content"])},
        "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock"])},
        "accountManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Management"])}
      }
    },
    "stock": {
      "global": {
        "numberOfData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of data"])},
        "lastAddedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last added date"])},
        "numberOfDataAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of data added"])},
        "parameterizedFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameterized file"])},
        "dataManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data management"])},
        "designManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design management"])},
        "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
        "on3Months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On 3 months"])},
        "on12Months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On 12 months"])},
        "fileNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File not found"])}
      },
      "activity": {
        "dateOfAddOrModData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of modification data"])},
        "averageLife": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average life"])},
        "whoAddedBDD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who added BDD"])},
        "dateOfArchiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of archiving"])},
        "whoArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who Archived"])},
        "dataActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active data"])},
        "dataInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive data"])},
        "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data will be deleted permanently, want to continue ?"])},
        "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data has been deleted successfully !"])},
        "errors": {
          "fileNotFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["File \"", _interpolate(_named("name")), "\" is not present in the server."])}
        }
      }
    },
    "filterModal": {
      "filterOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter options"])},
      "resetFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset filter"])},
      "applyFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply filter"])},
      "addedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added date"])},
      "au": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au"])},
      "du": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du"])},
      "global": {
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
        "byActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By activities"])},
        "searchActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search activity"])}
      },
      "activity": {
        "keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keywords"])},
        "keywordSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keywords search"])},
        "dataLifetime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lifetime of data"])},
        "whoAddedData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who added the data"])},
        "whoArchivedData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who archive the data"])},
        "statusOfData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status of data"])},
        "selectUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select User"])}
      }
    }
  },
  "layout": {
    "authLayout": {
      "administrationActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration Activities"])}
    }
  },
  "pages": {
    "auth": {
      "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forgot your password"])},
      "firstNameOrEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name or Email"])},
      "invalidUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid user name"])},
      "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid password"])},
      "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid credentials"])}
    },
    "dashboard": {
      "newContent": {
        "chooseActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chose an activity"])},
        "filterByDomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter by domain"])},
        "selectActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select activity"])},
        "chooseMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose an integration method"])},
        "parameterizedFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameterized files"])},
        "excelAndPictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel and pictures"])},
        "directIntegration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct integration"])},
        "dedicatedInterface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["via a dedicated interface"])},
        "chooseMethodFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the empty parameter Excel file, via the activity library"])},
        "importFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import files"])},
        "importYourFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import your file"])},
        "importArchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import archive"])},
        "archiveRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive required"])},
        "contentValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content validation"])},
        "entryCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You are going to add ", _interpolate(_named("dataCount")), " data!"])},
        "paraText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["However, content is pending because anomalies have been detected. Please check and edit the content so that it is sent to the administrators:"])},
        "duplicateData": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duplicateDataCount")), " data has been marked as duplicates."])},
        "choseDuplicates": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["You have chosen ", _interpolate(_named("choseDataCount")), " duplicate data to keep from ", _interpolate(_named("duplicateDataCount")), " duplicates."])},
        "toVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To verify"])},
        "emptyExcelFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel file is empty"])},
        "dataAddedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data added successfully!"])},
        "errors": {
          "difficultyLevel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The \"", _interpolate(_named("column")), "\" must be: facile, moyen or difficile. Please note that the words must be written in lowercase."])},
          "columnOrValueNotFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Column \"", _interpolate(_named("column")), "\" was not found or Data in column \"", _interpolate(_named("column")), "\" is missing. This column is mandatory."])},
          "dataValidationErrorHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make the necessary changes, depending on the level selected : "])},
          "wrongValueType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The data is missing or has the wrong type."])},
          "fileNotFoundInArchive": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["The file \"", _interpolate(_named("assetName")), "\" could not be found in the attachment."])}
        }
      }
    }
  }
}