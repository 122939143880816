import {
  SyncDataModule as sdm
} from '@/api/SynchronizeApi.service';

/**
 * Synchronize data modules from data manager to db store.
 *
 * @param dmoids Array of dmo_id
 * @returns {Array} List of dmo_id synchronized
 */
export const SyncDataModule = (dmoids: number[]) => {
  return new Promise((resolve, reject) => {
    sdm(dmoids)
      .then((result) => resolve(result))
      .catch((error) => reject(error.response ? error.response.data : error.message));
  });
};