import { api, dashApiPrefix } from '@/Api';
/**
 * Get assets from API
 *
 * @param {string[]} assetPaths Paths of assets
 * @return {object[]} Assets as buffer
 */
export const GetDataAssets = async (assetPaths: string[]) => {
    const result = await api.post(`${dashApiPrefix}/getassets`, { Paths: assetPaths });
    return result.data;
};
