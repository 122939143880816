
import { UseDataModal } from '@/composables/UseDataModal.composable';
import { ref } from '@vue/reactivity';
import { onMounted } from '@vue/runtime-core';
export default {
    name: 'DuplicateDataModal',
    props: {
        Title: String,
        Data: Object,
        onClose: Function,
        onSubmit: Function,
        selectedData: Array,
    },
    setup(props: any) {
        const { Table, TableHeader, isSmallTable, File, DownloadFiles } = UseDataModal();
        // get full table body to add duplicate rows dynamically.
        const TableBody = ref<HTMLElement>();
        const NewDataRow = ref<HTMLElement[]>();
        /**
         * Generate duplicate data rows of each original row
         */
        onMounted(() => {
            let duplicateRows: string[] = [];
            let dataAttribute = TableBody.value?.attributes[1].name;
            props.Data.values.forEach((row: any) => {
                let htmlDuplicateRows = ``;
                row.duplicates.forEach((duplicateRow: string[], index: number) => {
                    let htmlRow: string;
                    row.duplicates.length === index + 1
                        ? (htmlRow = `<tr class="duplicate-rows">
                        <td ${dataAttribute}></td>`)
                        : (htmlRow = `<tr ${dataAttribute}>
                        <td ${dataAttribute}></td>`);
                    duplicateRow.forEach((duplicateData: string) => {
                        duplicateData
                            ? (htmlRow += `<td ${dataAttribute}>${duplicateData}</td>`)
                            : (htmlRow += `<td ${dataAttribute}></td>`);
                    });
                    htmlRow += `</tr>`;
                    htmlDuplicateRows += htmlRow;
                });
                duplicateRows.push(htmlDuplicateRows);
            });
            NewDataRow.value?.forEach((el, index) => {
                el.insertAdjacentHTML('afterend', duplicateRows[index]);
            });
        });
        return { Table, TableHeader, isSmallTable, File, DownloadFiles, TableBody, NewDataRow };
    },
};
