import { api, syncApiPrefix } from '@/Api';

/**
 * Synchronize data modules from data manager to db store.
 *
 * @param dmoids Array of dmo_id
 * @returns {Array} List of dmo_id synchronized
 */
export const SyncDataModule = async (dmoids: number[]) => {
  const result = await api.post(`${syncApiPrefix}/datamodules`, { dmoids });
  return result.data;
}