
import { SidebarMenuItem } from '@/helper/interface/generic';
import { defineComponent, PropType } from 'vue';
import { useRoute } from 'vue-router';
export default defineComponent({
    name: 'SidebarTab',
    props: {
        data: Object as PropType<SidebarMenuItem[]>,
    },
    setup() {
        const route = useRoute();
        /**
         * Get icon url after compile
         *
         * @param {String} icon name of the icon
         * @return {String} url of the icon
         */
        const GetIconUrl = (icon: String) => {
            return require(`@/assets/menu-icons/${icon}`);
        };
        /**
         * Get current url endpoint
         *
         * @return {String} current url's endpoint
         */
        const GetUrlEndpoint = () => route.path;
        return { GetIconUrl, GetUrlEndpoint };
    },
});
