import { api, validationApiPrefix } from '@/Api';
import { ValidateExcelDataBody } from '@/helper/interface/ApiRequest';
/**
 * Validate excel data
 *
 * @param {ValidateExcelDataBody} body Contains:
 *                            -- TempId, Temporary table id of excel data
 *                            -- ActivityId, Id of an activity
 * @return {Object} Contains:
 *                  -- data, Array of excel data (if validated) with duplicates if found in the database
 *                  -- errors, Array of validation errors (if not validated)
 */
export const ValidateExcelData = async (body: ValidateExcelDataBody) => {
    const result = await api.post(`${validationApiPrefix}/validateexceldata`, body);
    return result.data;
};
