import { GetStockDataApi } from '@/api/GetStockDataApi.service';
import { GetStockDataResponse, StockGlobalDataResponse } from '@/helper/interface/ApiResponse';
import { StructureGlobalTableData } from './StructureTableData.service';
/**
 * Get all domains with there activities data
 *
 * @return {promise} list of all domains with there activities data
 */
export const GetStockData = () => {
    return new Promise<GetStockDataResponse>((resolve, reject) => {
        GetStockDataApi()
            .then((data: StockGlobalDataResponse[]) => resolve(StructureGlobalTableData(data)))
            .catch((error) => reject(error.response ? error.response.data : error.message));
    });
};
