import { api, dashApiPrefix } from '@/Api';
/**
 * Fetch all domains from the API
 *
 * @return {Array} All domains from the API
 */
export const GetAllDomains = async () => {
    const result = await api.post(`${dashApiPrefix}/getalldomains`, {});
    return result.data;
};
