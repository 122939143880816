
import { onMounted, PropType, ref, watch } from '@vue/runtime-core';
import { ActivityFilterForm } from '@/helper/interface/stock/FilterFormData';
import { useI18n } from 'vue-i18n';
import { ActivityDataResponse } from '@/helper/interface/ApiResponse';
export default {
    name: 'ActivityFilterModal',
    props: {
        filterFormData: Object as PropType<ActivityFilterForm>,
        tableData: Object as PropType<ActivityDataResponse[]>,
        onReset: Function,
        onApply: Function,
    },
    setup(props: any) {
        const greenEvo = 1;
        const orangeEvo = 2;
        const redEvo = 3;
        const activeData = 1;
        const inActiveData = -1;
        const { t } = useI18n();
        const wordSearch = ref<string>('');
        const addedUsers = ref<string[]>([]);
        const archivedUsers = ref<string[]>([]);
        const addedUser = ref<string>('');
        const archivedUser = ref<string>('');
        // add words to search
        const AddWords = () => {
            if (wordSearch.value.length) props.filterFormData.Words.push(wordSearch.value);
            wordSearch.value = '';
        };
        // remove words from previously added words
        const RemoveWord = (word: string) =>
            props.filterFormData?.Words.splice(props.filterFormData.Words.indexOf(word), 1);
        // remove addedUser from previously selected addedUsers
        const RemoveSelectedAddedUser = (user: string) =>
            props.filterFormData?.AddedUsers.splice(props.filterFormData.AddedUsers.indexOf(user), 1);
        // handle added user
        const HandleAddedUsers = () => {
            if (addedUser.value.length && !props.filterFormData.AddedUsers.includes(addedUser.value))
                props.filterFormData.AddedUsers.push(addedUser.value);
            addedUser.value = '';
        };
        // remove archivedUser from previously selected archivedUsers
        const RemoveSelectedArchivedUser = (user: string) =>
            props.filterFormData?.ArchivedUsers.splice(props.filterFormData.ArchivedUsers.indexOf(user), 1);
        // handle archived user
        const HandleArchivedUsers = () => {
            if (archivedUser.value.length && !props.filterFormData.ArchivedUsers.includes(archivedUser.value))
                props.filterFormData.ArchivedUsers.push(archivedUser.value);
            archivedUser.value = '';
        };
        // initialize users data
        const InitializeUsers = () => {
            addedUsers.value = [];
            archivedUsers.value = [];
            props.tableData.forEach((data: ActivityDataResponse) => {
                if (data.whoadded.user !== ' ' && !addedUsers.value.includes(data.whoadded.user))
                    addedUsers.value.push(data.whoadded.user);
                if (data.whoarchived.user !== ' ' && !archivedUsers.value.includes(data.whoarchived.user))
                    archivedUsers.value.push(data.whoarchived.user);
            });
        };
        // change users data upon changing table data
        watch(
            () => props.tableData,
            () => InitializeUsers(),
            { deep: true }
        );
        onMounted(() => InitializeUsers());
        return {
            t,
            greenEvo,
            orangeEvo,
            redEvo,
            activeData,
            inActiveData,
            AddWords,
            wordSearch,
            RemoveWord,
            addedUsers,
            archivedUsers,
            addedUser,
            archivedUser,
            HandleAddedUsers,
            RemoveSelectedAddedUser,
            HandleArchivedUsers,
            RemoveSelectedArchivedUser,
        };
    },
};
