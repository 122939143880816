import { api, dashApiPrefix } from '@/Api';
/**
 *  Delete temporary data from database
 *
 * @param {string} TempId Id of temporary data stored in database
 * @returns {*} no content
 */
export const DeleteTempDataApi = async (tempId: string | Blob) => {
    const result = await api.post(`${dashApiPrefix}/deletetempdata`, { TempId: tempId });
    return result.data;
};
