
import UserSection from '@/components/sidebar/user-section/UserSection.component.vue';
import SidebarMenu from '@/components/sidebar/menu/Menu.component.vue';
export default {
    name: 'Sidebar',
    components: {
        UserSection,
        SidebarMenu,
    },
};
