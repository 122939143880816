import { StructureDuplicateDataTable, StructureReadDataTable } from './StructureTableData.service';
import { ValidateFilesErrors } from '@/helper/interface/new-content/Errors';
import { ValidateExcel } from './Excel.service';
import {
    ValidateArchiveResponse,
    ValidateExcelDataResponse,
    ValidateFilesResponse,
} from '@/helper/interface/ApiResponse';
import { ValidateExcelDataBody } from '@/helper/interface/ApiRequest';
import { ValidateArchiveFile } from './Archive.service';
import i18n from '@/i18n';
/**
 * Validate files
 *
 * @param {string} tempId Temporary table id of excel data
 * @param {string} activityId Id of an activity
 * @param {Blob} archiveFile a zip file
 * @return {promise} contain: -- headers and -- values
 */
export const ValidateFiles = (tempId: string | Blob, activityId: string | Blob, archiveFile?: Blob | string) => {
    const tempIdField = 'TempId';
    const activityIdField = 'ActivityId';
    const archiveFileField = 'ArchiveFile';
    const validateExcelDataBody: ValidateExcelDataBody = {
        TempId: tempId,
        ActivityId: activityId,
    };
    let excelData: Object[];
    let duplicateData: Object[] | undefined;
    let genericSchema: Object;
    let excelFileErrors: string[];
    let archiveDataWithBuffer: ValidateArchiveResponse = {};
    let archiveDataWithUrl: ValidateArchiveResponse;
    let archiveFileErrors: string[];
    let errorMessages: string[] = [];
    return new Promise<ValidateFilesResponse>(async (resolve, reject) => {
        await ValidateExcel(validateExcelDataBody)
            .then((result: ValidateExcelDataResponse) => {
                if (!result.data.length) throw i18n.global.t('pages.dashboard.newContent.emptyExcelFile');
                excelData = result.data;
                genericSchema = result.genericSchema;
                if (result.duplicateData) duplicateData = result.duplicateData;
            })
            .catch((errors) => (excelFileErrors = errors));
        if (archiveFile) {
            const validateArchiveFormData = new FormData();
            validateArchiveFormData.append(tempIdField, tempId);
            validateArchiveFormData.append(activityIdField, activityId);
            validateArchiveFormData.append(archiveFileField, archiveFile);
            await ValidateArchiveFile(validateArchiveFormData)
                .then((data: ValidateArchiveResponse) => {
                    Object.entries(data).forEach(([key, value]) => {
                        archiveDataWithBuffer[key] = value;
                        let fileUrl = window.URL.createObjectURL(new Blob([Uint8Array.from(value.data).buffer]));
                        data[key] = fileUrl;
                    });
                    archiveDataWithUrl = data;
                })
                .catch((errors) => (archiveFileErrors = errors));
        }
        if (excelFileErrors || archiveFileErrors) {
            let rejectData: ValidateFilesErrors = { errors: [], excelError: false, archiveError: false };
            if (excelFileErrors) {
                rejectData.excelError = true;
                errorMessages = errorMessages.concat(excelFileErrors);
            }
            if (archiveFileErrors) {
                rejectData.archiveError = true;
                errorMessages = errorMessages.concat(archiveFileErrors);
            }
            rejectData.errors = errorMessages;
            reject(rejectData);
        } else {
            try {
                resolve({
                    importData: excelData,
                    readData: StructureReadDataTable(excelData, genericSchema, archiveDataWithUrl),
                    duplicateData: duplicateData?.length
                        ? StructureDuplicateDataTable(duplicateData, genericSchema, archiveDataWithUrl)
                        : undefined,
                    archiveData: archiveDataWithUrl ? archiveDataWithBuffer : undefined,
                });
            } catch (error: any) {
                reject(error.message);
            }
        }
    });
};
