import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import AppLayout from '../layout/app-layout/AppLayout.vue';
import AuthLayout from '../layout/auth-layout/AuthLayout.vue';
import Home from '../pages/dashboard/home/Home.page.vue';
import Login from '@/pages/auth/Login.page.vue';
import NewContent from '../pages/dashboard/new-content/NewContent.page.vue';
import Stock from '../pages/dashboard/stock/Stock.page.vue';
import AccountManagement from '../pages/dashboard/account-management/AccountManagement.page.vue';
import { getConfig } from '@/Api';
import ActivityDetails from '@/pages/dashboard/stock/activity-details/ActivityDetails.page.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/auth',
        component: AuthLayout,
        children: [
            {
                path: 'login',
                name: 'Login',
                component: Login,
            },
        ],
    },
    {
        path: '/',
        redirect: { path: '/home' },
    },
    {
        path: '/',
        name: 'app-layout',
        component: AppLayout,
        children: [
            {
                name: 'Home',
                path: 'home',
                component: Home,
            },
            {
                name: 'NewContent',
                path: 'new-content',
                component: NewContent,
            },
            {
                name: 'Stock',
                path: 'stock',
                component: Stock,
            },
            {
                name: 'ActivityDetails',
                component: ActivityDetails,
                path: 'activity-details',
                props: (route) => ({ ...route.params }),
            },
            {
                name: 'AccountManagement',
                path: 'account-management',
                component: AccountManagement,
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
// auth guard
const { localStorage } = window;
router.beforeEach(async (to, from) => {
    await getConfig(); // initializing configuration of a request
    if (to.name !== 'Login') {
        const authToken: string = localStorage.getItem('token') || '';
        if (!authToken.length) return { name: 'Login' };
    }
});

export default router;
