import { GetAllDomains } from '@/api/DomainApi.service';
import { DomainResponse } from '@/helper/interface/ApiResponse';
/**
 *Get all domains
 *
 * @return {promise} list of domains
 */
export const GetDomains = () => {
    return new Promise<DomainResponse[]>((resolve, reject) => {
        GetAllDomains()
            .then((data: DomainResponse[]) => resolve(data))
            .catch((error) => reject(error.response ? error.response.data : error.message));
    });
};
