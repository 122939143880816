
import Header from '@/components/header/Header.component.vue';
import ErrorModal from '@/components/modals/error-modal/ErrorModal.component.vue';
import ReadDataModal from '@/components/modals/read-data-modal/ReadDataModal.component.vue';
import DuplicateDataModal from '@/components/modals/duplicate-data-modal/DuplicateDataModal.component.vue';
import { GetDomains } from '@/services/Domain.service';
import { GetActivitiesByDomainId } from '@/services/Activity.service';
import { ValidateFiles } from '@/services/ValidateFiles.service';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { useI18n } from 'vue-i18n';
import { ref } from '@vue/reactivity';
import { computed, onMounted, onUnmounted } from '@vue/runtime-core';
import { UseErrorModal } from '@/composables/UseErrorModal.composable';
import { StepFormData } from '@/helper/interface/new-content/StepFormData';
import {
    ActivityResponse,
    DomainResponse,
    DuplicateDataResponse,
    ReadDataResponse,
    SaveExcelDataResponse,
    ValidateArchiveResponse,
    ValidateFilesResponse,
} from '@/helper/interface/ApiResponse';
import { RemoveDuplicates, SaveExcelData } from '@/services/Excel.service';
import { InsertData } from '@/services/Activity.service';
import { DeleteReadData } from '@/services/DeleteReadData.service';
import { ValidateFilesErrors } from '@/helper/interface/new-content/Errors';
import { useRouter } from 'vue-router';
import { GetLocalUser } from '@/services/Authentication.service';

export default {
    name: 'NewContent',
    components: {
        Header,
        Loading,
        ErrorModal,
        ReadDataModal,
        DuplicateDataModal,
    },
    setup() {
        const validExcelTypes = [
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ];
        const validArchiveTypes = ['application/zip', 'application/x-zip-compressed'];
        const domainActivityModal = 'domain_activity';
        const methodModal = 'method';
        const filesModal = 'files';
        const validationModal = 'validation';
        const activityIdField = 'ActivityId';
        const excelFileField = 'ExcelFile';
        const { t } = useI18n();
        const { errorMessage, errorModalOpen, OpenErrorModal, CloseErrorModal } = UseErrorModal();
        const ExcelInputFiled = ref<HTMLInputElement>();
        const ArchiveInputFiled = ref<HTMLInputElement>();
        const stepFormData = ref<StepFormData>({
            ActivityId: '',
            DomainId: '',
            ExcelFile: t('pages.dashboard.newContent.importYourFile'),
            ArchiveFile: t('pages.dashboard.newContent.importArchive'),
        });
        const modals = ref<string[]>([domainActivityModal]);
        const domains = ref<DomainResponse[]>([]);
        const activities = ref<ActivityResponse[]>([]);
        const isLoading = ref<Boolean>(false);
        const tempId = ref<string | Blob>('');
        const archiveRequire = ref<Boolean>(false);
        const excelFileError = ref<Boolean>(false);
        const archiveFileError = ref<Boolean>(false);
        const dataToImport = ref<object[]>([]);
        const archiveDataToSave = ref<ValidateArchiveResponse>();
        const readData = ref<ReadDataResponse>({
            headers: [],
            values: [],
        });
        const duplicateData = ref<DuplicateDataResponse>({
            headers: [],
            values: [],
        });
        const dataCountToSave = computed(
            () => readData.value.values.length + keepDuplicateDataIndex.value.length - duplicateData.value.values.length
        );
        const readDataModalOpen = ref<Boolean>(false);
        const duplicateDataModalOpen = ref<Boolean>(false);
        const duplicatesChecked = ref<Boolean>(false);
        const keepDuplicateDataIndex = ref<number[]>([]);
        const router = useRouter();
        // Handling first step continue button
        const isEnableFirstStepContinue = () => {
            return stepFormData.value.DomainId && stepFormData.value.ActivityId ? true : false;
        };
        // Handling third step continue button
        const isEnableThirdStepContinue = () => {
            if (typeof stepFormData.value.ExcelFile !== 'string' && !excelFileError.value && !isLoading.value) {
                if (archiveRequire.value) {
                    return typeof stepFormData.value.ArchiveFile !== 'string' && !archiveFileError.value ? true : false;
                }
                return true;
            }
            return false;
        };
        // Handling domains and activities
        const HandleDomainsAndActivity = (event: any) => {
            if (event.target.id === 'Domains') {
                stepFormData.value.ActivityId = ''; // to disabled next step
                if (tempId.value.toString.length) DeleteReadData(tempId.value);
                isLoading.value = true;
                GetActivitiesByDomainId(event.target.value)
                    .then((data: ActivityResponse[]) => (activities.value = data))
                    .catch((error) => OpenErrorModal([error]))
                    .finally(() => (isLoading.value = false));
            }
            modals.value.includes(methodModal) ? DefaultModal() : null;
        };
        // Handling file uploads
        const HandleFileUploads = (event: any) => {
            if (event.target.files.length) {
                modals.value = [domainActivityModal, methodModal, filesModal];
                switch (event.target.id) {
                    case 'ExcelFile':
                        if (validExcelTypes.includes(event.target.files[0].type)) {
                            if (tempId.value.toString.length) DeleteReadData(tempId.value);
                            excelFileError.value = false;
                            stepFormData.value.ExcelFile = event.target.files[0];
                            let formData = new FormData();
                            formData.append(activityIdField, stepFormData.value.ActivityId);
                            formData.append(excelFileField, stepFormData.value.ExcelFile);
                            isLoading.value = true;
                            SaveExcelData(formData)
                                .then((data: SaveExcelDataResponse) => {
                                    tempId.value = data.TempId;
                                    archiveRequire.value = data.ArchiveRequire;
                                })
                                .catch((error) => {
                                    if (tempId.value.toString.length) DeleteReadData(tempId.value);
                                    excelFileError.value = true;
                                    typeof error === 'string' ? OpenErrorModal([error]) : OpenErrorModal(error);
                                })
                                .finally(() => {
                                    isLoading.value = false;
                                });
                        } else {
                            excelFileError.value = true;
                            OpenErrorModal([t('generic.wrongFileType')]);
                        }
                        break;
                    case 'ArchiveFile':
                        if (validArchiveTypes.includes(event.target.files[0].type)) {
                            archiveFileError.value = false;
                            stepFormData.value.ArchiveFile = event.target.files[0];
                        } else {
                            archiveFileError.value = true;
                            OpenErrorModal([t('generic.wrongFileType')]);
                        }
                        break;
                }
            }
        };
        // Submit files to validate
        const SubmitFileToValidate = () => {
            isLoading.value = true;
            modals.value = [domainActivityModal, methodModal, filesModal];
            duplicatesChecked.value = false;
            duplicateData.value.headers = duplicateData.value.values = keepDuplicateDataIndex.value = [];
            if (archiveRequire.value && typeof stepFormData.value.ArchiveFile === 'string') {
                archiveFileError.value = true;
                OpenErrorModal([t('pages.dashboard.newContent.archiveRequired')]);
                isLoading.value = false;
            } else {
                ValidateFiles(
                    tempId.value,
                    stepFormData.value.ActivityId,
                    archiveRequire.value ? stepFormData.value.ArchiveFile : undefined
                )
                    .then((data: ValidateFilesResponse) => {
                        dataToImport.value = data.importData;
                        readData.value = data.readData;
                        if (data.archiveData) archiveDataToSave.value = data.archiveData;
                        if (data.duplicateData) duplicateData.value = data.duplicateData;
                        AddModal(validationModal);
                    })
                    .catch((error: ValidateFilesErrors | string) => {
                        if (typeof error !== 'string') {
                            excelFileError.value = error.excelError;
                            archiveFileError.value = error.archiveError;
                            OpenErrorModal(error.errors);
                        } else OpenErrorModal([error]);
                    })
                    .finally(() => (isLoading.value = false));
            }
        };
        // Set default modal and reset step form
        const DefaultModal = () => {
            if (tempId.value.toString.length) DeleteReadData(tempId.value);
            stepFormData.value.ExcelFile = t('pages.dashboard.newContent.importYourFile');
            stepFormData.value.ArchiveFile = t('pages.dashboard.newContent.importArchive');
            archiveRequire.value = false;
            archiveDataToSave.value = undefined;
            modals.value = [domainActivityModal];
        };
        // Add modals to show on new content page
        const AddModal = (modName: string) => {
            excelFileError.value = false;
            archiveFileError.value = false;
            modals.value.push(modName);
        };
        // Rise read data modal
        const OpenReadDataModal = (data?: ReadDataResponse) => {
            if (data) readData.value = data;
            modals.value = [domainActivityModal, methodModal, filesModal];
            readDataModalOpen.value = true;
        };
        // Close read data modal
        const CloseReadDataModal = () => {
            modals.value = [domainActivityModal, methodModal, filesModal, validationModal];
            readDataModalOpen.value = false;
        };
        // Rise duplicate data modal
        const OpenDuplicateDataModal = (data?: DuplicateDataResponse) => {
            if (data) duplicateData.value = data;
            modals.value = [domainActivityModal, methodModal, filesModal];
            duplicateDataModalOpen.value = true;
        };
        // Close duplicate data modal
        const CloseDuplicateDataModal = () => {
            AddModal(validationModal);
            duplicateDataModalOpen.value = false;
        };
        // Submit submit duplicate data modal
        const SubmitDuplicateDataModal = (selectedData: number[]) => {
            keepDuplicateDataIndex.value = selectedData;
            duplicatesChecked.value = true;
            CloseDuplicateDataModal();
        };
        // Get selected activity name
        const GetCurrentActivityName = () => {
            return activities.value.find((activity) => activity.act_id === stepFormData.value.ActivityId)?.act_label;
        };
        // Submit data to import
        const SubmitToImport = () => {
            const user = GetLocalUser();
            if (user) {
                isLoading.value = true;
                dataToImport.value = RemoveDuplicates(
                    duplicateData.value,
                    dataToImport.value,
                    keepDuplicateDataIndex.value
                );
                InsertData(stepFormData.value.ActivityId, user.Id, dataToImport.value, archiveDataToSave.value)
                    .then(() => {
                        window.alert(t('pages.dashboard.newContent.dataAddedSuccessfully'));
                        router.push({ name: 'Stock' });
                        DeleteReadData(tempId.value);
                    })
                    .catch((error) => (typeof error === 'string' ? OpenErrorModal([error]) : OpenErrorModal(error)))
                    .finally(() => (isLoading.value = false));
            } else OpenErrorModal([t('pages.auth.invalidUserName')]);
        };
        // Cancel Import
        const CancelImport = () => {
            isLoading.value = true;
            DeleteReadData(tempId.value)
                .then(() => {
                    tempId.value = '';
                    DefaultModal();
                })
                .catch((error) => OpenErrorModal([error]))
                .finally(() => (isLoading.value = false));
        };
        const GoBack = () => router.back();
        // handle data on mounted the component
        onMounted(() => {
            isLoading.value = true;
            GetDomains()
                .then((data) => {
                    domains.value = data;
                })
                .catch((error) => OpenErrorModal([error]))
                .finally(() => (isLoading.value = false));
        });
        // delete temp data on unmounted the component
        onUnmounted(() => {
            if (tempId.value.toString.length) DeleteReadData(tempId.value);
        });
        return {
            domainActivityModal,
            methodModal,
            filesModal,
            validationModal,
            t,
            stepFormData,
            modals,
            domains,
            activities,
            isLoading,
            archiveRequire,
            excelFileError,
            archiveFileError,
            errorMessage,
            readData,
            duplicateData,
            dataCountToSave,
            errorModalOpen,
            readDataModalOpen,
            duplicateDataModalOpen,
            duplicatesChecked,
            isEnableFirstStepContinue,
            isEnableThirdStepContinue,
            HandleDomainsAndActivity,
            HandleFileUploads,
            SubmitFileToValidate,
            DefaultModal,
            AddModal,
            CloseErrorModal,
            OpenReadDataModal,
            OpenDuplicateDataModal,
            CloseReadDataModal,
            CloseDuplicateDataModal,
            SubmitDuplicateDataModal,
            GetCurrentActivityName,
            keepDuplicateDataIndex,
            SubmitToImport,
            CancelImport,
            ExcelInputFiled,
            ArchiveInputFiled,
            GoBack,
        };
    },
};
