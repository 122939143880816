
import Sidebar from '@/components/sidebar/Sidebar.component.vue';
import { useRouter } from 'vue-router';
export default {
    name: 'AppLayout',
    components: {
        Sidebar,
    },
    setup() {
        const router = useRouter();
        const { localStorage } = window;
        // Handle logout
        const HandleLogout = () => {
            localStorage.clear();
            router.push({ name: 'Login' });
        };
        return { HandleLogout };
    },
};
