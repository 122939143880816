import { ValidateArchiveData } from '@/api/ValidateArchiveDataApi.service';
import { ValidateArchiveResponse } from '@/helper/interface/ApiResponse';
import i18n from '@/i18n';
/**
 * Validate archive file
 *
 * @param {FormData} formData
 * Contains: -- TempId, Temporary table id of excel data
 *           -- ActivityId, Id of an activity
 *           -- ArchiveFile, a zip file
 * @return {ValidateArchiveResponse}
 * Contains: -- Archive, extracted files(if validated)
 */
export const ValidateArchiveFile = (formData: FormData) => {
    return new Promise<ValidateArchiveResponse>((resolve, reject) => {
        ValidateArchiveData(formData)
            .then((data) => {
                if (data.errors) {
                    reject(
                        data.errors.map((error: { type: string; name: string }) => {
                            if (error.type === 'NOT_FOUND')
                                return i18n.global.t('pages.dashboard.newContent.errors.fileNotFoundInArchive', {
                                    assetName: error.name,
                                });
                        })
                    );
                } else resolve(data.archive);
            })
            .catch((error) => {
                reject(error.response ? error.response.data : error.message);
            });
    });
};
