import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6fabdc58"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (tab) => {
      return (_openBlock(), _createElementBlock("li", {
        key: tab.Id
      }, [
        _createVNode(_component_router_link, {
          to: tab.Path
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass({
                        selected:
                            _ctx.GetUrlEndpoint() === tab.Path ||
                            (_ctx.GetUrlEndpoint() === '/activity-details' && tab.Path === '/stock'),
                    })
            }, [
              _createElementVNode("img", {
                src: _ctx.GetIconUrl(tab.Icon)
              }, null, 8, _hoisted_2),
              _createElementVNode("span", null, _toDisplayString(tab.Name), 1)
            ], 2)
          ]),
          _: 2
        }, 1032, ["to"])
      ]))
    }), 128))
  ]))
}