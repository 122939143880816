import { ReadExcel } from '@/api/ReadExcelApi.service';
import { ValidateExcelData } from '@/api/ValidateExcelDataApi.service';
import { ValidateExcelDataBody } from '@/helper/interface/ApiRequest';
import {
    DuplicateDataResponse,
    SaveExcelDataResponse,
    ValidateExcelDataResponse,
} from '@/helper/interface/ApiResponse';
import i18n from '@/i18n';
const localize = require('ajv-i18n');
const difficultyLevelColumnName = 'Niveau de difficulté';
/**
 * Save excel data on temporary table in database
 *
 * @param {FormData} formData Contains:
 *                            -- ActivityId, Id of an activity
 *                            -- ExcelFile, an excel file
 * @return {SaveExcelDataResponse} Contains:
 *                 -- TempId, Temporary table id of the excel data
 *                 -- structuredDataId, Temporary table id of the final structured data
 *                 -- ArchiveRequire, (boolean) if archive require for excel data
 */
export const SaveExcelData = (formData: FormData) => {
    return new Promise<SaveExcelDataResponse>((resolve, reject) => {
        ReadExcel(formData)
            .then((data) => {
                if (data.errors) {
                    const errors = CustomizeReadExcelBuiltinErrors(data.errors);
                    reject(errors);
                } else resolve({ TempId: data.tempId, ArchiveRequire: data.archiveRequire });
            })
            .catch((error) => {
                reject(error.response ? error.response.data : error.message);
            });
    });
};
/**
 * Customizing read excel inbuilt errors
 *
 * @param {object[]} errors An read-excel-file returned errors
 * @return {string[]} Contains: customized errors
 */
export const CustomizeReadExcelBuiltinErrors = (errors: any) => {
    errors.forEach((err: any) => {
        // Remove duplicate errors and keep one.
        errors
            .filter((e: any) => e.column === err.column && e.error === err.error)
            .forEach((e: any) => {
                errors.splice(errors.indexOf(e), 1);
            });
        // Customize errors
        errors.splice(
            0,
            0,
            err.column === difficultyLevelColumnName
                ? i18n.global.t('pages.dashboard.newContent.errors.difficultyLevel', { column: err.column })
                : i18n.global.t('pages.dashboard.newContent.errors.columnOrValueNotFound', { column: err.column })
        );
    });
    errors.reverse();
    return errors;
};
/**
 * Validate excel file data
 *
 * @param {ValidateExcelDataBody} validateExcelDataBody Contains:
 *                            -- TempId, Temporary table id of excel data
 *                            -- ActivityId, Id of an activity
 * @return {Object[]} Contains:
 *                  -- data, Array of excel data (if validated)
 */
export const ValidateExcel = (validateExcelDataBody: ValidateExcelDataBody) => {
    return new Promise<ValidateExcelDataResponse>((resolve, reject) => {
        ValidateExcelData(validateExcelDataBody)
            .then((response) => {
                response.errors ? reject(HandleInvalidDataErrors(response.errors)) : resolve(response);
            })
            .catch((error) => reject(error.response ? error.response.data : error.message));
    });
};
/**
 * Handle invalid data errors
 *
 * @param {object[]} invalidDataErrors validation error from backend(ajv library)
 * @return {string[]} customized errors
 */
export const HandleInvalidDataErrors = (invalidDataErrors: any) => {
    let errors = [i18n.global.t('pages.dashboard.newContent.errors.dataValidationErrorHeader')];
    localize[i18n.global.locale](invalidDataErrors);
    invalidDataErrors.forEach((error: any) =>
        errors.push(
            error.keyword === 'type'
                ? `${i18n.global.t('generic.row')} ${error.row}: ${i18n.global.t(
                      'pages.dashboard.newContent.errors.wrongValueType'
                  )}`
                : `${i18n.global.t('generic.row')} ${error.row}: ${error.message}.`
        )
    );
    return errors;
};
/**
 * Remove duplicate data from excel read data
 *
 * @param {DuplicateDataResponse} duplicateData duplicates found
 * @param {object[]} dataToImport data to save in the database
 * @param {number[]} keepDataIndex duplicate data indices to keep
 * @return {object[]} data to save in the database
 */
export const RemoveDuplicates = (
    duplicateData: DuplicateDataResponse,
    dataToImport: object[],
    keepDataIndex: number[]
) => {
    let deleteIndex = duplicateData.values.map((row) => {
        if (!keepDataIndex.includes(row.index)) return row.index;
    });
    dataToImport = dataToImport.filter((data) => !deleteIndex.includes(dataToImport.indexOf(data)));
    return dataToImport;
};
