import { api, excelApiPrefix } from '@/Api';
/**
 * Get data after reading an excel file
 *
 * @param {FormData} formData Contains:
 *                            -- ActivityId, Id of an activity
 *                            -- ExcelFile, an excel file
 * @return {Object} Contains:
 *                 -- tempId, Temporary table id of the excel data
 *                 -- archiveRequire, (boolean) if archive require for excel data
 *                 -- errors, occurs during reading excel file
 */
export const ReadExcel = async (formData: FormData) => {
    const result = await api.post(`${excelApiPrefix}/readexcel`, formData);
    return result.data;
};
