
import { UseFilterModal } from '@/composables/UseFilterModal.composable';
import { ref } from '@vue/reactivity';
import { onMounted, watch } from '@vue/runtime-core';
import { useI18n } from 'vue-i18n';
import { GlobalFilterForm } from '@/helper/interface/stock/FilterFormData';
export default {
    name: 'GlobalFilterModal',
    props: {
        tableData: Object,
        onReset: Function,
        onApply: Function,
        onClose: Function,
        globalFilterFormData: Object,
    },
    setup(props: any) {
        const greenArrow = 1;
        const orangeArrow = 2;
        const redArrow = 3;
        const { t } = useI18n();
        const { GetDomainsFromGlobalTable, GetActivitiesFromGlobalTable } = UseFilterModal();
        const domains = ref<string[]>([]);
        const activities = ref<string[]>([]);
        const searchedActivities = ref<string[]>([]);
        const searchedActivity = ref<string>('');
        const openActivities = ref<boolean>(false);
        const filterFormData = ref<GlobalFilterForm>({
            ViewExpand: false,
            DateExpand: false,
            ArrowExpand: false,
            Domain: '',
            Activities: [],
            StartDate: '',
            EndDate: '',
            Arrow: [],
        });
        // get activities from table data
        const GetActivities = () => {
            activities.value =
                filterFormData.value.Domain.length > 0
                    ? GetActivitiesFromGlobalTable(props.tableData.rows, filterFormData.value.Domain)
                    : GetActivitiesFromGlobalTable(props.tableData.rows);
            searchedActivities.value = activities.value;
            openActivities.value = true;
        };
        // handle or manage searching of activities
        const HandleActivities = (activity: string) => {
            if (!filterFormData.value.Activities.includes(activity)) filterFormData.value.Activities.push(activity);
            searchedActivity.value = '';
            openActivities.value = false;
        };
        // remove previously selected activity
        const RemoveSelectedActivity = (activity: string) =>
            filterFormData.value.Activities.splice(filterFormData.value.Activities.indexOf(activity), 1);
        // search activity in activity list
        const SearchActivity = () => {
            const regex = new RegExp(searchedActivity.value, 'gi');
            searchedActivities.value = activities.value.filter((w) => regex.test(w));
        };
        /**
         * Close activity list upon click outside of input field
         */
        function CloseActivitiesOnClickBody(e: any) {
            let clickOnModal = e.composedPath().filter((value: any) => value.id === 'SearchActivity');
            if (!clickOnModal.length) openActivities.value = false;
        }
        watch(
            () => openActivities.value,
            (isOpen) =>
                isOpen
                    ? window.addEventListener('click', CloseActivitiesOnClickBody, true)
                    : window.removeEventListener('click', CloseActivitiesOnClickBody, true)
        );
        // get all domains from table data
        onMounted(() => {
            if (props.tableData) domains.value = GetDomainsFromGlobalTable(props.tableData.rows);
            filterFormData.value = props.globalFilterFormData;
        });
        return {
            t,
            domains,
            activities,
            filterFormData,
            GetActivities,
            HandleActivities,
            RemoveSelectedActivity,
            greenArrow,
            orangeArrow,
            redArrow,
            openActivities,
            SearchActivity,
            searchedActivity,
            searchedActivities,
        };
    },
};
