import {
    ChangeActivityDataStatusApi,
    DeleteActivityDataApi,
    GetActivities,
    GetActivityDataByActId,
    InsertActivityData,
} from '@/api/ActivityApi.service';
import { GetActivityDataByActIdBody, InsertActivityDataBody } from '@/helper/interface/ApiRequest';
import { ActivityResponse, GetActivityDataResponse, ValidateArchiveResponse } from '@/helper/interface/ApiResponse';
import { LocalActivityDetails } from '@/helper/interface/generic';
/**
 *Get all activities for a domain
 *
 * @return {promise} list of activities
 */
export const GetActivitiesByDomainId = (domainId: number) => {
    return new Promise<ActivityResponse[]>((resolve, reject) => {
        GetActivities(domainId)
            .then((data: ActivityResponse[]) => {
                data.forEach(
                    (activity) =>
                        (activity.act_label = activity.act_label.charAt(0).toUpperCase() + activity.act_label.slice(1))
                );
                resolve(data);
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
};
/**
 *Get all activity data by activity id
 *
 * @return {promise} generic schema and activity data
 */
export const GetActivityData = (actId: number) => {
    let reqBody: GetActivityDataByActIdBody = { ActivityId: actId };
    return new Promise<GetActivityDataResponse>((resolve, reject) => {
        GetActivityDataByActId(reqBody)
            .then((data: GetActivityDataResponse) => {
                data.activityData = data.activityData.map((row) => {
                    if (row.added) row.added = row.added.substring(0, 10);
                    if (row.whoadded) row.whoadded = ReformatUserDetails(row.whoadded, '#166b90');
                    if (row.archived) row.archived = row.archived.substring(0, 10);
                    if (row.whoarchived) row.whoarchived = ReformatUserDetails(row.whoarchived, '#ee4af1');
                    return row;
                });
                resolve(data);
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
};
/**
 * Reformat user name to user abbreviation and marge with background color.
 *
 * @param {string} userName user name
 * @param {string} bgColor color code
 * @return {object} reformated object
 */
const ReformatUserDetails = (userName: string, bgColor: string) => {
    const usrName: string[] = userName.split(' ');
    const userCode = usrName[0].length && usrName[1].length ? usrName[0][0] + usrName[1][0] : '/';
    return {
        user: userName,
        userCode,
        bgColor,
    };
};
/**
 * Insert activity data to database
 *
 * @param {(string | Blob)} actId Activity id
 * @param {object[]} data JSON data of the activity
 * @param {ValidateArchiveResponse} [files] Files data if activity have assets
 * @return {object[]} inserted data
 */
export const InsertData = (actId: string | Blob, userId: number, data: object[], files?: ValidateArchiveResponse) => {
    let insertDataBody: InsertActivityDataBody = {
        ActivityId: actId,
        UserId: userId,
        InsertData: data,
        Files: files,
    };
    return new Promise<object[]>((resolve, reject) => {
        InsertActivityData(insertDataBody)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => reject(error.response ? error.response.data : error.message));
    });
};
/**
 *  Set activity data status enable or disable in database
 *
 * @param {boolean} isEnable Array of data module id
 * @param {number[]} dmoIds Array of data module id
 * @returns {*}  no content
 */
export const EnableOrDisableActData = (isEnable: boolean, dmoIds: number[], userId: number) => {
    return new Promise((resolve, reject) => {
        ChangeActivityDataStatusApi(isEnable, dmoIds, userId)
            .then((result) => resolve(result))
            .catch((error) => reject(error.response ? error.response.data : error.message));
    });
};
/**
 *  Set activity data as delete in database
 *
 * @param {number[]} dmoIds Array of data module id
 * @returns {*}  no content
 */
export const DeleteActivityData = (dmoIds: number[]) => {
    return new Promise((resolve, reject) => {
        DeleteActivityDataApi(dmoIds)
            .then((result) => resolve(result))
            .catch((error) => reject(error.response ? error.response.data : error.message));
    });
};
/**
 * Get recently visited activity details of activity-details page
 *
 * @return {LocalActivityDetails | null} Activity details
 */
export const GetLocalActivityDetails = () => {
    const strActInfo = localStorage.getItem('latestVisitedActivity');
    let actInfo: LocalActivityDetails | null = null;
    if (strActInfo) actInfo = JSON.parse(strActInfo);
    return actInfo;
};
/**
 * Locally set recently visited activity details of activity details page
 *
 * @param {LocalActivityDetails} actInfo activity details
 * @return {LocalActivityDetails} activity details
 */
export const SetLocalActivityDetails = (actInfo: LocalActivityDetails) => {
    let currentLocalActInfo: LocalActivityDetails | null = GetLocalActivityDetails();
    if (actInfo.ActId && currentLocalActInfo && currentLocalActInfo.ActId !== actInfo.ActId)
        localStorage.setItem('latestVisitedActivity', JSON.stringify(actInfo));
    else if (!currentLocalActInfo) localStorage.setItem('latestVisitedActivity', JSON.stringify(actInfo));
    return actInfo;
};
