
import { ref } from '@vue/reactivity';
import Header from '@/components/header/Header.component.vue';
import ErrorModal from '@/components/modals/error-modal/ErrorModal.component.vue';
import ActivityFilterModal from '@/components/modals/filter-modal/ActivityFilterModal.component.vue';
import { useI18n } from 'vue-i18n';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { SyncDataModule } from '@/services/Synchronize.service';
import {
    DeleteActivityData,
    EnableOrDisableActData,
    GetActivityData,
    GetLocalActivityDetails,
    SetLocalActivityDetails,
} from '@/services/Activity.service';
import { ActivityDataResponse, GetActivityDataResponse, ReadDataResponse } from '@/helper/interface/ApiResponse';
import { StructureActivityDataTable } from '@/services/StructureTableData.service';
import { UseErrorModal } from '@/composables/UseErrorModal.composable';
import { computed, onMounted, watch } from '@vue/runtime-core';
import { ActivityFilterForm } from '@/helper/interface/stock/FilterFormData';
import { UseFilterModal } from '@/composables/UseFilterModal.composable';
import { GetLocalUser } from '@/services/Authentication.service';
import { useRouter } from 'vue-router';
import { convertDateToFranceOrder } from '@/helper/utils/dateUtils';
import { GetAssets } from '@/services/File.service';

export default {
    name: 'ActivityDetails',
    components: {
        Header,
        ActivityFilterModal,
        ErrorModal,
        Loading,
    },
    props: {
        actId: {
            type: String,
            default: GetLocalActivityDetails()?.ActId,
        },
        actName: {
            type: String,
            default: GetLocalActivityDetails()?.ActName,
        },
        domName: {
            type: String,
            default: GetLocalActivityDetails()?.DomName,
        },
    },
    setup(props: any) {
        const levelEasy = 'tag_easy';
        const levelMedium = 'tag_medium';
        const levelHard = 'tag_hard';
        const isLoading = ref<Boolean>(false);
        const { t } = useI18n();
        const router = useRouter();
        const { errorMessage, errorModalOpen, OpenErrorModal, CloseErrorModal } = UseErrorModal();
        const { GetFilteredActivityData, ToggleFilter } = UseFilterModal();
        const tableData = ref<{ headers: string[]; values: ActivityDataResponse[] }>({ headers: [], values: [] });
        const activityFilterModalOpen = ref<boolean>(false);
        const filteredTableValues = ref<ActivityDataResponse[]>([]);
        const selectedData = ref<Number[]>([]);
        const allSelected = ref<boolean>(false);
        const pagination: any = ref({
            currentPage: 1,
            perPage: 50,
            totalPages: computed(() => Math.ceil(filteredTableValues.value.length / pagination.value.perPage)),
        });
        const paginatedItems = computed(() => {
            const start = (pagination.value.currentPage - 1) * pagination.value.perPage;
            const stop = start + pagination.value.perPage;
            return filteredTableValues.value.slice(start, stop);
        });
        const activityFilterFormData = ref<ActivityFilterForm>({
            DateExpand: false,
            DataExpand: false,
            Levels: [levelEasy, levelMedium, levelHard],
            StartDate: '',
            Words: [],
            AddedUsers: [],
            ArchivedUsers: [],
            EndDate: '',
            Evolution: [],
            Status: [],
        });
        const isActHasAssets = computed(() => paginatedItems.value.find((value) => value.assets));
        // Initialize default table data
        const InitializeTableData = () => {
            tableData.value = { headers: [], values: [] };
            filteredTableValues.value = [];
        };
        // Get table data
        const GetTableData = (initializeTable: boolean = true) => {
            isLoading.value = true;
            if (initializeTable) InitializeTableData();
            GetActivityData(props.actId)
                .then((response: GetActivityDataResponse) => {
                    tableData.value.values = response.activityData;
                    const structuredData: ReadDataResponse = StructureActivityDataTable(
                        response.activityData,
                        response.genericSchema
                    );
                    tableData.value.headers = structuredData.headers;
                    structuredData.values.forEach((value, index) => (tableData.value.values[index].data = value));
                    filteredTableValues.value = tableData.value.values;
                    FilterDataLevelWise(activityFilterFormData.value.Levels);
                    ApplyActivityFilter();
                    activityFilterModalOpen.value = false;
                })
                .catch((error) => OpenErrorModal([error]))
                .finally(() => (isLoading.value = false));
        };
        // Change activity data enable status
        const ChangeActDataStatus = (status: boolean, dmoIds: number[]) => {
            const user = GetLocalUser();
            if (user) {
                isLoading.value = true;
                EnableOrDisableActData(status, dmoIds, user.Id)
                    .catch((error) => OpenErrorModal([error]))
                    .finally(() => {
                        SyncDataModule(dmoIds);
                        GetTableData(false);
                    });
            } else OpenErrorModal([t('pages.auth.invalidUserName')]);
        };
        // Manage data levels
        const ManageLevels = (level: string) =>
            activityFilterFormData.value.Levels.includes(level)
                ? activityFilterFormData.value.Levels.splice(activityFilterFormData.value.Levels.indexOf(level), 1)
                : activityFilterFormData.value.Levels.push(level);
        // Select all data
        const SelectAllData = () => {
            selectedData.value = [];
            if (!allSelected.value) paginatedItems.value.forEach((row) => selectedData.value.push(row.id));
        };
        // remove all select
        const RemoveAllSelect = () => {
            allSelected.value = false;
            selectedData.value = [];
        };
        // Change activity data enable status for all selected rows
        const ChangeAllDataStatus = (status: boolean, dmoIds: number[]) => {
            selectedData.value.length
                ? (ChangeActDataStatus(status, dmoIds), RemoveAllSelect())
                : OpenErrorModal([t('generic.pleaseChooseAData')]);
        };
        // Change all selected data status as delete
        const DeleteAllData = (dmoIds: number[]) => {
            if (selectedData.value.length) {
                if (window.confirm(t('components.stock.activity.confirmDelete'))) {
                    isLoading.value = true;
                    DeleteActivityData(dmoIds)
                        .then(() => {
                            SyncDataModule(dmoIds);
                            window.alert(t('components.stock.activity.deleteSuccess'));
                            RemoveAllSelect();
                        })
                        .catch((error) => OpenErrorModal([error]))
                        .finally(() => {
                            GetTableData(false);
                        });
                }
            } else OpenErrorModal([t('generic.pleaseChooseAData')]);
        };
        /**
         * Download assets for a data
         * @param assetPaths list of assets paths
         */
        const DownloadAssets = (assetPaths: string[]) => {
            isLoading.value = true;
            GetAssets(assetPaths)
                .then((response) => {
                    Object.entries(response.files).forEach(([key, value]) => {
                        const link = document.createElement('a');
                        link.href = value;
                        link.download = key;
                        link.click();
                        URL.revokeObjectURL(link.href);
                    });
                    if (response.errors.length) OpenErrorModal(response.errors);
                })
                .catch((error) => OpenErrorModal([error]))
                .finally(() => {
                    isLoading.value = false;
                });
        };
        // Get color for evolution of data added
        const GetColor = (modifiedDate: string) => {
            const year = parseInt(modifiedDate.substring(0, 4));
            const month = parseInt(modifiedDate.substring(5, 7));
            const currentDate = new Date().toISOString();
            const currentYear = parseInt(currentDate.substring(0, 4));
            const currentMonth = parseInt(currentDate.substring(5, 7));
            const monthDiff = MonthDifference(new Date(year, month), new Date(currentYear, currentMonth));
            return monthDiff <= 6 ? '#4ba953' : monthDiff > 6 && monthDiff <= 12 ? '#ffa051' : '#f04248';
        };
        // Difference between two months
        const MonthDifference = (dateFrom: Date, dateTo: Date) => {
            return dateTo.getMonth() - dateFrom.getMonth() + 12 * (dateTo.getFullYear() - dateFrom.getFullYear());
        };
        // Apply activity details table filter
        const ApplyActivityFilter = () => {
            if (filteredTableValues.value) {
                filteredTableValues.value = GetFilteredActivityData(
                    filteredTableValues.value,
                    activityFilterFormData.value
                );
            }
            RemoveAllSelect();
            pagination.value.currentPage = 1;
            activityFilterModalOpen.value = ToggleFilter(activityFilterModalOpen.value);
        };
        // Reset activity details table filter
        const ResetActivityFilter = () => {
            filteredTableValues.value = tableData.value.values;
            activityFilterFormData.value.DateExpand = false;
            activityFilterFormData.value.DataExpand = false;
            activityFilterFormData.value.Levels = [levelEasy, levelMedium, levelHard];
            activityFilterFormData.value.StartDate = '';
            activityFilterFormData.value.Words = [];
            activityFilterFormData.value.AddedUsers = [];
            activityFilterFormData.value.ArchivedUsers = [];
            activityFilterFormData.value.EndDate = '';
            activityFilterFormData.value.Evolution = [];
            activityFilterFormData.value.Status = [];
            pagination.value.currentPage = 1;
            RemoveAllSelect();
            activityFilterModalOpen.value = ToggleFilter(activityFilterModalOpen.value);
        };
        const GoBack = () => router.back();
        // Filter data level wise
        const FilterDataLevelWise = (levels: string[]) => {
            if (levels.length >= 3) filteredTableValues.value = tableData.value.values;
            else
                levels.length
                    ? (filteredTableValues.value = tableData.value.values.filter((value) =>
                          levels.includes(value.level)
                      ))
                    : (filteredTableValues.value = []);
        };
        /**
         * Close Activity filter modal upon click outside of modal
         *
         */
        function CloseModalOnClickBody(e: any) {
            let clickOnModal = e
                .composedPath()
                .filter((value: any) => value.className === 'modal-container' || value.id === 'ActFilterButton');
            if (!clickOnModal.length) activityFilterModalOpen.value = false;
        }
        // convert date to france order
        const convertDate = (dateToConvert: string) => convertDateToFranceOrder(dateToConvert);

        watch(
            () => activityFilterModalOpen.value,
            (isOpen) =>
                isOpen
                    ? window.addEventListener('click', CloseModalOnClickBody, true)
                    : window.removeEventListener('click', CloseModalOnClickBody, true)
        );
        watch(
            () => activityFilterFormData.value.Levels,
            (levels) => {
                try {
                    isLoading.value = true;
                    FilterDataLevelWise(levels);
                    RemoveAllSelect();
                    pagination.value.currentPage = 1;
                } catch (error: any) {
                    OpenErrorModal([error]);
                } finally {
                    isLoading.value = false;
                }
            },
            { deep: true }
        );
        onMounted(() => {
            GetTableData();
            SetLocalActivityDetails({ ActId: props.actId, ActName: props.actName, DomName: props.domName });
        });
        return {
            t,
            GetColor,
            tableData,
            selectedData,
            errorMessage,
            errorModalOpen,
            isLoading,
            OpenErrorModal,
            CloseErrorModal,
            ChangeActDataStatus,
            allSelected,
            SelectAllData,
            DeleteAllData,
            ChangeAllDataStatus,
            filteredTableValues,
            activityFilterFormData,
            levelEasy,
            levelMedium,
            levelHard,
            ManageLevels,
            ToggleFilter,
            activityFilterModalOpen,
            ApplyActivityFilter,
            ResetActivityFilter,
            GoBack,
            convertDate,
            paginatedItems,
            pagination,
            RemoveAllSelect,
            isActHasAssets,
            DownloadAssets,
        };
    },
};
