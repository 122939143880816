import i18n from '@/i18n';
import { GetDataAssets } from './../api/GetAssets.service';
import { GetGenericSchema } from './Schema.service';
const writeXlsxFile = require('write-excel-file');

/**
 * Get file url
 *
 * @param {number} actId buffer object from api
 * @return {promise<string>} URL to download the file
 */
export const GetFileUrl = (actId: number) => {
    return new Promise<string>((resolve, reject) => {
        GetGenericSchema(actId)
            .then(async (schema: any) => {
                const columnNames = getColumnNames(schema);
                const headerRow = columnNames.map((columnName: string) => {
                    return {
                        value: columnName,
                        align: 'center',
                        alignVertical: 'center',
                        color: '#ffffff',
                        backgroundColor: '#000000',
                        wrap: true,
                    };
                });
                const file = await writeXlsxFile([headerRow], { buffer: true });
                resolve(window.URL.createObjectURL(file));
            })
            .catch((error) => {
                reject(error.response ? error.response.data : error.message);
            });
    });
};
/**
 * Get column names from schema
 *
 * @param {object} schema the generic schema from the database
 * @return {string[]} list of columns
 */
export const getColumnNames = (schema: any) => {
    let columnNames: string[] = [];
    schema.columns.forEach((column: any) => {
        if (column.type === 'array') {
            column.values.forEach((value: any) => {
                if (value.type === 'string' || value.type === 'boolean') columnNames.push(value.columnName);
                else if (value.type === 'object') {
                    value.columns.forEach((col: any) => {
                        if (col.type === 'string' || col.type === 'boolean') columnNames.push(col.columnName);
                    });
                }
            });
        } else if (column.type === 'object') {
            column.values.forEach((col: any) => {
                if (col.type === 'string' || col.type === 'boolean') columnNames.push(col.columnName);
            });
        } else if (column.type === 'string' || column.type === 'boolean') columnNames.push(column.columnName);
    });
    return columnNames;
};

/**
 * Get assets downloadable link
 *
 * @param {string[]} assetPaths paths of assets
 * @return {*} file download urls
 */
export const GetAssets = (assetPaths: string[]) => {
    return new Promise<{ files: { [key: string]: string }; errors: string[] }>((resolve, reject) => {
        GetDataAssets(assetPaths)
            .then((response: { files: object; errors: { name: string; type: string }[] }) => {
                let result: { files: { [key: string]: string }; errors: string[] } = { files: {}, errors: [] };
                Object.entries(response.files).forEach(([key, value]) => {
                    result.files[key] = window.URL.createObjectURL(new Blob([Uint8Array.from(value.data).buffer]));
                });
                response.errors.forEach((error) => {
                    if (error.type === 'NOT_FOUND')
                        result.errors.push(
                            i18n.global.t('components.stock.activity.errors.fileNotFound', { name: error.name })
                        );
                });
                resolve(result);
            })
            .catch((error) => {
                reject(error.response ? error.response.data : error.message);
            });
    });
};
