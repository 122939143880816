
import Header from '@/components/header/Header.component.vue';
import Global from '@/components/stock/global/Global.component.vue';
import GlobalFilterModal from '@/components/modals/filter-modal/GlobalFilterModal.component.vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { useI18n } from 'vue-i18n';
import { ref } from '@vue/reactivity';
import { onMounted, watch } from 'vue';
import { GetStockData } from '@/services/GetStockData.service';
import { GetStockDataResponse } from '@/helper/interface/ApiResponse';
import { UseErrorModal } from '@/composables/UseErrorModal.composable';
import ErrorModal from '@/components/modals/error-modal/ErrorModal.component.vue';
import { UseFilterModal } from '@/composables/UseFilterModal.composable';
import { GlobalFilterForm } from '@/helper/interface/stock/FilterFormData';
import { useRouter } from 'vue-router';
export default {
    name: 'Stock',
    components: {
        Header,
        Global,
        GlobalFilterModal,
        Loading,
        ErrorModal,
    },
    setup() {
        const { t } = useI18n();
        const router = useRouter();
        const { errorMessage, errorModalOpen, OpenErrorModal, CloseErrorModal } = UseErrorModal();
        const { GetFilteredGlobalData, ToggleFilter } = UseFilterModal();
        const isLoading = ref<Boolean>(false);
        const globalTableData = ref<GetStockDataResponse>();
        const globalTableFilteredData = ref<GetStockDataResponse>();
        const globalFilterModalOpen = ref<boolean>(false);
        const globalFilterFormData = ref<GlobalFilterForm>({
            ViewExpand: false,
            DateExpand: false,
            ArrowExpand: false,
            Domain: '',
            Activities: [],
            StartDate: '',
            EndDate: '',
            Arrow: [],
        });
        // Apply global table filter
        const ApplyGlobalFilter = (filterFormData: GlobalFilterForm) => {
            globalFilterFormData.value = filterFormData;
            if (globalTableData.value) {
                globalTableFilteredData.value = GetFilteredGlobalData(globalTableData.value, filterFormData);
            }
            globalFilterModalOpen.value = ToggleFilter(globalFilterModalOpen.value);
        };
        // Reset global table filter
        const ResetGlobalFilter = () => {
            globalTableFilteredData.value = globalTableData.value;
            globalFilterFormData.value = {
                ViewExpand: false,
                DateExpand: false,
                ArrowExpand: false,
                Domain: '',
                Activities: [],
                StartDate: '',
                EndDate: '',
                Arrow: [],
            };
            globalFilterModalOpen.value = ToggleFilter(globalFilterModalOpen.value);
        };
        // Open activity table with activity data
        const OpenActivityPage = (actId: number, domName: string, actName: string) => {
            router.push({
                name: 'ActivityDetails',
                params: {
                    actId,
                    actName,
                    domName,
                },
            });
            globalFilterModalOpen.value = false;
        };
        const GoBack = () => router.back();
        /**
         *
         * Close Global filter modal upon click outside of modal
         */
        function CloseModalOnClickBody(e: any) {
            let clickOnModal = e
                .composedPath()
                .filter((value: any) => value.className === 'modal-container' || value.id === 'FilterButton');
            if (!clickOnModal.length) globalFilterModalOpen.value = false;
        }
        watch(
            () => globalFilterModalOpen.value,
            (isOpen) =>
                isOpen
                    ? window.addEventListener('click', CloseModalOnClickBody, true)
                    : window.removeEventListener('click', CloseModalOnClickBody, true)
        );
        // Get global data from database
        onMounted(() => {
            isLoading.value = true;
            GetStockData()
                .then(
                    (result: GetStockDataResponse) => (
                        (globalTableData.value = result), (globalTableFilteredData.value = result)
                    )
                )
                .catch((error) => OpenErrorModal([error]))
                .finally(() => (isLoading.value = false));
        });
        return {
            t,
            OpenActivityPage,
            globalFilterModalOpen,
            ToggleFilter,
            globalTableData,
            globalTableFilteredData,
            errorMessage,
            errorModalOpen,
            CloseErrorModal,
            isLoading,
            ApplyGlobalFilter,
            ResetGlobalFilter,
            globalFilterFormData,
            GoBack,
        };
    },
};
