import { computed, onMounted, ref } from 'vue';

export function UseDataModal() {
    const File = ref<HTMLElement[] | null>(null);
    const Table = ref<HTMLElement | null>(null);
    const TableHeader = ref<HTMLElement | null>(null);
    const tableHeaderWidth = ref(100);
    const isSmallTable = computed(() => {
        return tableHeaderWidth.value < 99;
    });
    /**
     * Download all files of a row
     *
     * @param {number} index row index
     */
    const DownloadFiles = (index: number) => {
        if (File.value)
            File.value.forEach((value) => {
                if (value.classList.contains(index.toString())) value.click();
            });
    };
    onMounted(() => {
        // get the table width
        tableHeaderWidth.value =
            Table.value?.getBoundingClientRect().width && TableHeader.value?.getBoundingClientRect().width
                ? Math.round(
                      (100 * TableHeader.value?.getBoundingClientRect().width) /
                          Table.value?.getBoundingClientRect().width
                  )
                : 0;
    });
    return { Table, TableHeader, isSmallTable, File, DownloadFiles };
}
