import { ref } from 'vue';

export function UseErrorModal() {
    const errorMessage = ref<string[]>([]);
    const errorModalOpen = ref<Boolean>(false);
    /**
     * Open error modal
     *
     * @param {string[]} message error messages to show
     */
    const OpenErrorModal = (message: string[]) => {
        errorMessage.value = message;
        errorModalOpen.value = true;
    };
    /**
     * Close error modal
     *
     */
    const CloseErrorModal = () => {
        errorModalOpen.value = false;
        errorMessage.value = [];
    };
    return {
        errorMessage,
        errorModalOpen,
        OpenErrorModal,
        CloseErrorModal,
    };
}
