import { api, dashApiPrefix } from '@/Api';
/**
 * Fetch all domains with there activities data
 *
 * @return {object[]} all domains with there activities data
 */
export const GetStockDataApi = async () => {
    const result = await api.post(`${dashApiPrefix}/getstockdata`, {});
    return result.data;
};
