import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createElementBlock as _createElementBlock, vModelDynamic as _vModelDynamic, withKeys as _withKeys, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/eye.svg'
import _imports_1 from '@/assets/closed-eyes.png'


const _withScopeId = n => (_pushScopeId("data-v-4d45c37a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form vld-parent" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "error-container"
}
const _hoisted_4 = { class: "pass-container" }
const _hoisted_5 = ["type", "placeholder"]
const _hoisted_6 = {
  key: 0,
  src: _imports_0,
  class: "cursor-pointer",
  alt: "view"
}
const _hoisted_7 = {
  key: 1,
  src: _imports_1,
  class: "cursor-pointer",
  alt: "hidden"
}
const _hoisted_8 = {
  key: 0,
  class: "error-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErrorModal = _resolveComponent("ErrorModal")!
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.errorModalOpen)
      ? (_openBlock(), _createBlock(_component_ErrorModal, {
          key: 0,
          messages: $setup.errorMessage || [$setup.t('generic.error')],
          onClose: $setup.CloseErrorModal
        }, null, 8, ["messages", "onClose"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_loading, {
        active: $setup.isLoading,
        "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => (($setup.isLoading) = $event)),
        "can-cancel": false,
        "is-full-page": true
      }, null, 8, ["active"]),
      _createElementVNode("div", null, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('generic.login')), 1)
      ]),
      _createElementVNode("div", null, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.loginForm.UserName) = $event)),
          type: "text",
          placeholder: $setup.t('pages.auth.firstNameOrEmail'),
          onKeypress: _cache[2] || (_cache[2] = ($event: any) => ($setup.loginFormError.UserNameError = '')),
          class: _normalizeClass({ 'invalid-input': $setup.loginFormError.UserNameError.length })
        }, null, 42, _hoisted_2), [
          [_vModelText, $setup.loginForm.UserName]
        ]),
        ($setup.loginFormError.UserNameError.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("span", null, _toDisplayString($setup.loginFormError.UserNameError), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: _normalizeClass(["input-container", { 'invalid-input': $setup.loginFormError.PasswordError.length }])
        }, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.loginForm.Password) = $event)),
            type: $setup.passwordInputFieldType,
            placeholder: $setup.t('generic.password'),
            onKeypress: [
              _cache[4] || (_cache[4] = ($event: any) => ($setup.loginFormError.PasswordError = '')),
              _cache[5] || (_cache[5] = _withKeys(
//@ts-ignore
(...args) => ($setup.SubmitForm && $setup.SubmitForm(...args)), ["enter"]))
            ]
          }, null, 40, _hoisted_5), [
            [_vModelDynamic, $setup.loginForm.Password]
          ]),
          _createElementVNode("div", {
            class: "pass-img-container",
            onClick: _cache[6] || (_cache[6] = ($event: any) => ($setup.passwordInputFieldType = $setup.passwordInputFieldType === 'text' ? 'password' : 'text'))
          }, [
            ($setup.passwordInputFieldType === 'text')
              ? (_openBlock(), _createElementBlock("img", _hoisted_6))
              : (_openBlock(), _createElementBlock("img", _hoisted_7))
          ])
        ], 2),
        ($setup.loginFormError.PasswordError.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("span", null, _toDisplayString($setup.loginFormError.PasswordError), 1)
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('pages.auth.forgotPassword')) + "?", 1)
      ]),
      _createElementVNode("div", {
        onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => ($setup.SubmitForm && $setup.SubmitForm(...args))),
        class: "button-container"
      }, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('generic.login')), 1)
      ])
    ])
  ], 64))
}