
import { User } from '@/helper/interface/ApiResponse';
import { useI18n } from 'vue-i18n';
import { GetLocalUser } from '@/services/Authentication.service';
export default {
    name: 'UserSection',
    setup() {
        const { t } = useI18n();
        // get saved current user from local storage
        const currentUser: User | null = GetLocalUser();
        return { t, currentUser };
    },
};
