import { api, dashApiPrefix } from '@/Api';
/**
 * Get excel generic schema of an activity from database
 *
 * @param {number} actId An activity id
 * @return {object} Excel Generic schema from database
 */
export const GetGenericSchemaApi = async (actId: number) => {
    const result = await api.post(`${dashApiPrefix}/genericschema`, { ActivityId: actId });
    return result.data;
};
