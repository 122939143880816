export default {
  "generic": {
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifié"])},
    "attention": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
    "wrongFileType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de fichier incorrect"])},
    "keep": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garder"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
    "easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facile"])},
    "medium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyen"])},
    "difficult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Difficile"])},
    "global": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Num"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactif"])},
    "enable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])},
    "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimez"])},
    "modify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "pleaseChooseAData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez choisir une donnée"])},
    "evolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évolution"])},
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
    "row": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ligne"])},
    "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur"])},
    "assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des atouts"])}
  },
  "components": {
    "sidebar": {
      "menu": {
        "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
        "newContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau Contenu"])},
        "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock"])},
        "accountManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion De Compte"])}
      }
    },
    "stock": {
      "global": {
        "numberOfData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de données"])},
        "lastAddedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière date d'ajout"])},
        "numberOfDataAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de données ajoutées"])},
        "parameterizedFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier paramétré"])},
        "dataManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des données"])},
        "designManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion design"])},
        "domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domaine"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
        "on3Months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur 3 mols"])},
        "on12Months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur 12 mols"])},
        "fileNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier introuvable"])}
      },
      "activity": {
        "dateOfAddOrModData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de modification"])},
        "averageLife": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de vie donnée"])},
        "whoAddedBDD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui ajout BDD"])},
        "dateOfArchiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'archivage"])},
        "whoArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui archivage"])},
        "dataActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données actives"])},
        "dataInactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données inactives"])},
        "confirmDelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données seront définitivement supprimées, voulez-vous continuer ?"])},
        "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données ont été supprimées avec succès !"])},
        "errors": {
          "fileNotFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le fichier \"", _interpolate(_named("name")), "\" n'est pas présent sur le serveur."])}
        }
      }
    },
    "filterModal": {
      "filterOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option de filtre"])},
      "resetFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le filtre"])},
      "applyFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer filtre"])},
      "addedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'ajout"])},
      "au": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au"])},
      "du": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du"])},
      "global": {
        "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affichage"])},
        "byActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par-activités"])},
        "searchActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche d'activité"])}
      },
      "activity": {
        "keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mots clés"])},
        "keywordSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot clé de recherche"])},
        "dataLifetime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de vie de données"])},
        "whoAddedData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui a ajouté la données"])},
        "whoArchivedData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui archive les données"])},
        "statusOfData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut des données"])},
        "selectUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste utilisateurs"])}
      }
    }
  },
  "layout": {
    "authLayout": {
      "administrationActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités Administratives"])}
    }
  },
  "pages": {
    "auth": {
      "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mot de passe oublié"])},
      "firstNameOrEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom ou Email"])},
      "invalidUserName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur invalide"])},
      "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe invalide"])},
      "invalidCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les informations d'identification invalides"])}
    },
    "dashboard": {
      "newContent": {
        "chooseActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir une activité"])},
        "filterByDomain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer par domaine"])},
        "selectActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner l'activité"])},
        "chooseMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choix d'une méthode d'intégration"])},
        "parameterizedFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des fichiers paramètrès"])},
        "excelAndPictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel et photos"])},
        "directIntegration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intègration directe"])},
        "dedicatedInterface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["via une interface dèdièe"])},
        "chooseMethodFooter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargez le template paramétré via le stock des activités."])},
        "importFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer les fichiers"])},
        "importYourFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer votre fichier"])},
        "importArchive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer les images (.zip)"])},
        "archiveRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive requise"])},
        "contentValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation du contenu"])},
        "entryCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous allez importer ", _interpolate(_named("dataCount")), " donnée(s)!"])},
        "paraText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cependant, du contenu est en attente car des anomalies ont été détectées. Merci de vérifier et modifier le contenu afin qu’il soit envoyé aux administrateurs:"])},
        "duplicateData": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duplicateDataCount")), " des données ont été signatées comme doublons."])},
        "choseDuplicates": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Vous avez choisi ", _interpolate(_named("choseDataCount")), " données en double à conserver parmi ", _interpolate(_named("duplicateDataCount")), " doublons."])},
        "toVerify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier"])},
        "emptyExcelFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le fichier Excel est vide"])},
        "dataAddedSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données ajoutées avec succès !"])},
        "errors": {
          "difficultyLevel": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le \"", _interpolate(_named("column")), "\" doit être : facile, moyen ou difficile. Attention, les mots doivent être écrits en minuscule."])},
          "columnOrValueNotFound": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La colonne \"", _interpolate(_named("column")), "\" n'a pas été trouvée ou Une donnée dans la colonne \"", _interpolate(_named("column")), "\" est manquante. Cette colonne est obligatoire."])},
          "dataValidationErrorHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci d’effectuer les modifications nécessaires, selon le niveau sélectionné : "])},
          "wrongValueType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données sont manquantes ou ont un type incorrect."])},
          "fileNotFoundInArchive": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Le fichier \"", _interpolate(_named("assetName")), "\" est introuvable dans les fichiers joints."])}
        }
      }
    }
  }
}