import {
    ValidateArchiveResponse,
    ReadDataResponse,
    DuplicateDataResponse,
    DuplicateDataResponseValues,
    ActivityDataResponse,
    StockGlobalDataResponse,
} from '@/helper/interface/ApiResponse';
import { MapDuplicateTableData, MapTableData } from './MapTableData.service';
/**
 * Structure data for table
 *
 * @param {object[]} excelData Data from excel
 * @param {ValidateArchiveResponse} archiveData data from archive
 * @return {ReadDataResponse} contain: -- headers and -- values
 */
export const StructureReadDataTable = (excelData: any[], genericSchema: any, archiveData?: ValidateArchiveResponse) => {
    let tableData: ReadDataResponse = { headers: [], values: [] };
    let values: string[][] = [];
    let rowTableData: object[] = MapTableData(excelData, genericSchema);
    rowTableData.forEach((row) => {
        let rowValues = Object.values(row);
        if (archiveData) values.push(ManageArchive(rowValues, archiveData));
        else values.push(rowValues);
    });
    let headers: string[] = Object.keys(rowTableData[0]);
    if (archiveData) headers.push('Telecharger');
    tableData.headers = headers;
    tableData.values = values;
    return tableData;
};
/**
 * Structure duplicate data for table
 *
 * @param {object[]} duplicateData Data from excel
 * @param {ValidateArchiveResponse} archiveData data from archive
 * @return {DuplicateDataResponse} contain: -- headers and -- values
 */
export const StructureDuplicateDataTable = (
    duplicateData: any[],
    genericSchema: any,
    archiveData?: ValidateArchiveResponse
) => {
    let tableData: DuplicateDataResponse = { headers: [], values: [] };
    let values: DuplicateDataResponseValues[] = [];
    let rowTableData: { index: number; data: object; duplicates: object[] }[] = MapDuplicateTableData(
        duplicateData,
        genericSchema
    );
    rowTableData.forEach((row) => {
        let rowValues = Object.values(row.data);
        let duplicates: string[][] = [];
        row.duplicates.forEach((dupl) => duplicates.push(Object.values(dupl)));
        if (archiveData) values.push({ index: row.index, data: ManageArchive(rowValues, archiveData), duplicates });
        else values.push({ index: row.index, data: rowValues, duplicates });
    });
    let headers: string[] = Object.keys(rowTableData[0].data);
    if (archiveData) headers.push('Telecharger');
    tableData.headers = headers;
    tableData.values = values;
    return tableData;
};
const ManageArchive = (rowValues: any[], archiveData: ValidateArchiveResponse) => {
    rowValues.forEach((val, index) => {
        if (Object.keys(archiveData).includes(val)) rowValues[index] = { [val]: archiveData[val] };
    });
    rowValues.push({ isDownload: true });
    return rowValues;
};
/**
 * Structure activity data for activity table of stock page
 *
 * @param {GetActivityDataResponse} actDataResponse Data from excel
 * @return {DuplicateDataResponse} contain: -- headers and -- values
 */
export const StructureActivityDataTable = (actDataResponse: ActivityDataResponse[], schema: any) => {
    const mappedData = actDataResponse.map((actData: ActivityDataResponse) => {
        return { data: actData.data };
    });
    return StructureReadDataTable(mappedData, schema);
};

/**
 * Structure global table data
 *
 * @param {StockGlobalDataResponse[]} tableData Get stock data api response
 * @return {object} structured data
 */
export const StructureGlobalTableData = (tableData: StockGlobalDataResponse[]) => {
    let easyData: number = 0;
    let mediumData: number = 0;
    let hardData: number = 0;
    let globalData: number = 0;
    let totalThreeMonthsData: number = 0;
    let totalOneYearData: number = 0;
    tableData = tableData.map((row) => {
        if (row.datalastmodified) row.datalastmodified = row.datalastmodified.substring(0, 10);
        easyData += row.easy;
        mediumData += row.medium;
        hardData += row.hard;
        globalData += row.global;
        totalThreeMonthsData += row.threemonthsdata;
        totalOneYearData += row.oneyeardata;
        return row;
    });
    tableData = tableData.sort(
        (present: any, next: any) =>
            present.domain.charCodeAt(0) - next.domain.charCodeAt(0) ||
            present.activity.charCodeAt(0) - next.activity.charCodeAt(0)
    );
    return {
        rows: tableData,
        easyData,
        mediumData,
        hardData,
        globalData,
        totalThreeMonthsData,
        totalOneYearData,
    };
};
