
import { ref } from '@vue/reactivity';
import { useI18n } from 'vue-i18n';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ErrorModal from '@/components/modals/error-modal/ErrorModal.component.vue';
import { UseErrorModal } from '@/composables/UseErrorModal.composable';
import { LoginForm, LoginFormErrors } from '@/helper/interface/generic';
import { GetUser, SetLocalUser } from '@/services/Authentication.service';
import { GetUserResponse } from '@/helper/interface/ApiResponse';
import { setAuthToken } from '@/Api';
import { useRouter } from 'vue-router';
export default {
    name: 'Login',
    components: {
        Loading,
        ErrorModal,
    },
    setup() {
        const { t } = useI18n();
        const router = useRouter();
        const invalidUserName = t('pages.auth.invalidUserName');
        const invalidPassword = t('pages.auth.invalidPassword');
        const passwordInputFieldType = ref<string>('password');
        const isLoading = ref<Boolean>(false);
        const { errorMessage, errorModalOpen, OpenErrorModal, CloseErrorModal } = UseErrorModal();
        const loginForm = ref<LoginForm>({ UserName: '', Password: '' });
        const loginFormError = ref<LoginFormErrors>({ UserNameError: '', PasswordError: '' });
        // submit to login
        const SubmitForm = () => {
            if (loginForm.value.UserName.length && loginForm.value.Password.length) {
                isLoading.value = true;
                GetUser(loginForm.value.UserName, loginForm.value.Password)
                    .then((data: GetUserResponse) => {
                        setAuthToken(data.AuthToken);
                        SetLocalUser(data.User);
                        router.push({ name: 'Home' });
                    })
                    .catch((error) => OpenErrorModal([error]))
                    .finally(() => (isLoading.value = false));
            } else {
                if (!loginForm.value.UserName.length) loginFormError.value.UserNameError = invalidUserName;
                if (!loginForm.value.Password.length) loginFormError.value.PasswordError = invalidPassword;
            }
        };
        return {
            t,
            loginForm,
            SubmitForm,
            errorMessage,
            errorModalOpen,
            CloseErrorModal,
            isLoading,
            loginFormError,
            passwordInputFieldType,
        };
    },
};
