import { api, dashApiPrefix } from '@/Api';
import { GetActivityDataByActIdBody, InsertActivityDataBody } from '@/helper/interface/ApiRequest';
import { GetActivityDataResponse } from '@/helper/interface/ApiResponse';
/**
 * Fetch all activities from the API
 *
 * @param {number} domainId A domain ID
 * @return {Array} All activities for the domain from the API
 */
export const GetActivities = async (domainId: number) => {
    const result = await api.post(`${dashApiPrefix}/getallactivitiesbydomid`, { dom_id: domainId });
    return result.data;
};
/**
 * Fetch activity data by activity id from the API
 *
 * @param {GetActivityDataByActIdBody} reqBody
 * @return {GetActivityDataResponse} All activity data
 */
export const GetActivityDataByActId = async (reqBody: GetActivityDataByActIdBody) => {
    const result = await api.post(`${dashApiPrefix}/getactivitydatabyactid`, reqBody);
    return result.data as GetActivityDataResponse;
};
/**
 * IMport excel data
 *
 * @param {InsertActivityDataBody} body Contains:
 *                            -- ActivityId, Id of an activity
 *                            -- InsertData, Data with level
 * @return {Object} Contains:
 *                  -- data, Array of inserted data
 */
export const InsertActivityData = async (body: InsertActivityDataBody) => {
    const result = await api.post(`${dashApiPrefix}/insertactivitydata`, body);
    return result.data;
};
/**
 *  Set activity data status enable or disable in database
 *
 * @param {boolean} isEnable true or false
 * @param {number[]} dmoIds Array of data module id
 * @param {number} userId current user id
 * @returns {*}  no content
 */
export const ChangeActivityDataStatusApi = async (isEnable: boolean, dmoIds: number[], userId: number) => {
    const result = await api.post(`${dashApiPrefix}/setactivitydatastatus`, {
        Enable: isEnable,
        DmoIds: dmoIds,
        WhoArchived: userId,
    });
    return result.data;
};
/**
 *  Set activity data as delete in database
 *
 * @param {number[]} dmoIds Array of data module id
 * @returns {*}  no content
 */
export const DeleteActivityDataApi = async (dmoIds: number[]) => {
    const result = await api.post(`${dashApiPrefix}/deleteactivitydata`, { DmoIds: dmoIds });
    return result.data;
};
