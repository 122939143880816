import axios from 'axios';

const { localStorage } = window;
let authToken = localStorage.getItem('token') || '';

/**
 * Save auth token in local storage
 *
 * @param {string} newToken authentication token from api
 * @return {*} auth token
 */
export const setAuthToken = (newToken: string) => {
    authToken = newToken;
    localStorage.setItem('token', authToken);
    return authToken;
};
// prefixes
export const authApiPrefix = '/auth';
export const dashApiPrefix = '/dash';
export const excelApiPrefix = '/excel';
export const validationApiPrefix = '/validation';
export const syncApiPrefix = '/sync';
// API
export const api = axios.create();

// different configuration are mounted on each container at this location
// Call from main for get config
// Config Sample
/*
    {
        apiUrl: "",
        apiKey: "",
        apiVersion: 1

    }
*/
export const getConfig = async () => {
    const result = await axios.get('/config.json');
    const conf = result.data;
    api.defaults.baseURL = conf.apiUrl;
    api.defaults.headers.common['X-Api-Version'] = conf.apiVersion;
    api.defaults.headers.common['X-Api-Key'] = conf.apiKey;
    return conf;
};

// add token on each request
api.interceptors.request.use((config) => {
    if (config.headers) config.headers['X-Auth-Token'] = authToken;
    return config;
});
