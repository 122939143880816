
import MenuItems from '@/components/sidebar/menu-items/MenuItems.component.vue';
import { SidebarMenuItem } from '@/helper/interface/generic';
import { useI18n } from 'vue-i18n';

export default {
    name: 'SidebarMenu',
    components: {
        MenuItems,
    },
    setup() {
        const { t } = useI18n();
        // Pages in the menubar
        const menuItems: SidebarMenuItem[] = [
            {
                Id: 1,
                Name: t('components.sidebar.menu.home'),
                Icon: 'home.png',
                Path: '/home',
            },
            {
                Id: 2,
                Name: t('components.sidebar.menu.newContent'),
                Icon: 'add.png',
                Path: '/new-content',
            },
            {
                Id: 3,
                Name: t('components.sidebar.menu.stock'),
                Icon: 'stock.png',
                Path: '/stock',
            },
            {
                Id: 4,
                Name: t('components.sidebar.menu.accountManagement'),
                Icon: 'unlock.png',
                Path: '/account-management',
            },
        ];
        return { menuItems };
    },
};
