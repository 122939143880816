import { DeleteTempDataApi } from '@/api/DeleteTempDataApi.service';
/**
 *  Delete temporary data from database
 *
 * @return {promise} no content
 */
export const DeleteReadData = (tempId: string | Blob) => {
    return new Promise((resolve, reject) => {
        DeleteTempDataApi(tempId)
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error.response.data);
            });
    });
};
