import { GetGenericSchemaApi } from '@/api/SchemaApi.service';
/**
 * Get excel generic schema of an activity from database
 *
 * @param {number} actId An activity id
 * @return {object} Excel Generic schema from database
 */
export const GetGenericSchema = (actId: number) => {
    return new Promise((resolve, reject) => {
        GetGenericSchemaApi(actId)
            .then((data) => resolve(data))
            .catch((error) => reject(error.response ? error.response.data : error.message));
    });
};
