import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-81cde9d4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-block" }
const _hoisted_2 = { class: "modal-container" }
const _hoisted_3 = { class: "modal-header" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = { class: "text-container" }
const _hoisted_6 = { class: "modal-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.$t('generic.error')) + ":", 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.messages.slice(0, 10), (message, index) => {
            return (_openBlock(), _createElementBlock("p", { key: index }, _toDisplayString(message), 1))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          class: "close-container",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($props.onClose && $props.onClose(...args)))
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t('generic.close')), 1)
        ])
      ])
    ])
  ]))
}